import React, {useEffect, useState} from "react";
import {createStripeIntent, getPaymentMethods, removeCardData, setCardPrimary} from "../../Authentication/auth.api";
import {Loading} from "../../../common/components/Loading";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {AddPaymentMethod} from "./add-payment-method";
import Button from "../../../common/components/button";
import {AmericanCard, DinersCard, JCBCard, MasterCard, UnionPayCard, VisaCard} from "../../../utils/images-export";
import {ToasterStore} from "../../../stores/toaster.store";

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHER_KEY
);
export const UserPaymentMethods = () => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [stripeSecret, setStripeSecret] = useState(false);
    const [showAddCard, setShowAddCard] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(function () {
        createStripeIntent().then((response) => {
            console.log(response, '=-===');
            setStripeSecret(response.client_secret)
        }).catch(e => {
        });
    }, []);
    useEffect(async function () {
        setLoading(true);
        setPaymentMethods(await getPaymentMethods());
        setLoading(false);
    }, []);

    function toggleAddCard() {
        setShowAddCard(!showAddCard);
    }

    async function closeAddCardModal() {
        setPaymentMethods(await getPaymentMethods());
        toggleAddCard();
    }
    async function setCard(upm_id) {
        await setCardPrimary({upm_id});
        setPaymentMethods(await getPaymentMethods());

    }

    async function removeCard(upm_id) {
        try {
            await removeCardData({upm_id});
            ToasterStore.setSuccessMessage('Card removed successfully.');
            setPaymentMethods(await getPaymentMethods());
        } catch (e) {
            ToasterStore.setErrorMessage(e.message)
        }

    }

    if (loading) {
        return <Loading/>;
    }
    return <>
        <div >
            <h4 className="text-center text-primary underline">Payment Methods</h4>
            <div className="d-flex justify-content-end">
                {
                    !showAddCard && <Button title={"+ Add Card"} className="btn-sm" onClick={toggleAddCard}/> /*:
                        <Button title={"X"} className={"btn-danger btn-sm"} onClick={toggleAddCard}/>*/
                }
            </div>
            <div className={"add-card-section " + (showAddCard ? 'active' : '')}>
                {stripeSecret ? (
                    <Elements
                        stripe={stripePromise}
                        options={{clientSecret: stripeSecret}}
                    >
                        <AddPaymentMethod getPaymentMethods={getPaymentMethods} onClose={closeAddCardModal}/>
                    </Elements>
                ) : (
                    <Loading/>
                )}
            </div>
            <div className="profile-details pb-5">
                <h5 className="text-muted">Payment Methods</h5>

                {
                    paymentMethods && paymentMethods.length ? paymentMethods.map((card, key) => (
                            <div className="d-flex justify-content-between card-tile">
                                <div
                                    className="d-flex justify-content-start align-items-start card-container">
                                    {
                                        card.card_brand === 'Visa' && <div className="pl-2">
                                            <span className="brand-name small light">Visa</span>
                                            <div className="d-flex align-items-center gap-3">
                                                <img
                                                    src={VisaCard}
                                                    className="page-logo"/>
                                                <span className="ml-2 card-number"> XXXX XXXX XXXX {card.card_last4} </span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        card.card_brand === 'American Express' && <div className="pl-2">
                                            <span className="brand-name small light">American Express</span>
                                            <div className="d-flex align-items-center gap-3">
                                                <img
                                                    src={AmericanCard}
                                                    className="page-logo"/>
                                                <span className="ml-2 card-number"> XXXX XXXX XXXX {card.card_last4} </span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        card.card_brand === 'MasterCard' && <div className="pl-2">
                                            <span className="brand-name small light">Master Card</span>
                                            <div className="d-flex align-items-center gap-3">
                                                <img className="page-logo"
                                                 src={MasterCard}/>
                                            <span className="ml-2 card-number"> XXXX XXXX XXXX {card.card_last4} </span>
                                        </div>
                                    </div>
                                }
                                {
                                    card.card_brand === 'Discover' && <div className="pl-2">
                                        <span className="brand-name small light">Discover</span>
                                        <div className="d-flex align-items-center gap-3">
                                            <img
                                                className="page-logo" src={DinersCard}/>
                                            <span className="ml-2 card-number"> XXXX XXXX XXXX {card.card_last4} </span>
                                        </div>
                                    </div>
                                }
                                {
                                    card.card_brand === 'JCB' && <div className="pl-2">
                                        <span className="brand-name small light">JCB</span>
                                        <div className="d-flex align-items-center gap-3">
                                            <img className="page-logo"
                                                 src={JCBCard}/>
                                            <span className="ml-2 card-number"> XXXX XXXX XXXX {card.card_last4} </span>
                                        </div>
                                    </div>
                                }
                                {
                                    card.card_brand === 'UnionPay' && <div className="pl-2">
                                        <span className="brand-name small light">Union Pay</span>
                                        <div className="d-flex align-items-center gap-3">
                                            <img className="page-logo"
                                                 src={UnionPayCard}/>
                                            <span className="ml-2 card-number"> XXXX XXXX XXXX {card.card_last4} </span>
                                        </div>
                                    </div>
                                }
                                {
                                    card.card_brand === 'Diners Club' && <div className="pl-2">
                                        <span className="brand-name small light">Diners Club</span>
                                        <div className="d-flex align-items-center gap-3">
                                            <img className=" page-logo"
                                                 src={DinersCard}/>
                                            <span className="ml-2 card-number"> XXXX XXXX XXXX {card.card_last4} </span>
                                        </div>
                                    </div>
                                }


                            </div>
                            <div>
                                {
                                    card.isPrimary > 0 ? <span
                                            className={' text-white bg-success rounded-pill px-2 py-1 small'}>Primary</span>
                                        :
                                        <div className="d-flex gap-1">
                                            <span
                                                onClick={() => setCard(card.upm_id)}
                                                className={'mark-primary text-white bg-primary rounded-pill px-2 py-1 small'}>Set Primary</span>
                                            <span
                                                onClick={() => removeCard(card.upm_id)}
                                                className={'mark-primary text-white bg-danger rounded-pill px-2 py-1 small'}>Remove</span>

                                        </div>
                                }
                            </div>


                            </div>
                        ))
                        : <p className="text-muted text-center">No cards added</p>
                }
            </div>

        </div>
    </>
};
