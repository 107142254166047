import {action, makeObservable, observable} from 'mobx';

class NavStoreData {
    routeTree = [];
    match = {};
    basePath = {};

    constructor() {
        makeObservable(this, {
            routeTree: observable,
            match: observable,
            basePath: observable,
            updateRouteTree: action,
            updateMatch: action,
            updateBasePath: action,
        })
    }

    updateRouteTree(routeTree) {
        this.routeTree = routeTree;
    }

    updateMatch(match) {
        this.match = match;
    }

    updateBasePath(basePath) {
        this.basePath = basePath;
    }
}

export const NavStore = new NavStoreData();
