import React, {forwardRef, useRef} from "react";
import {UploadImage} from "../../common/components/UploadImage";
import Button from "../../common/components/button";
import {Formik} from "formik";
import * as Yup from "yup";

const brokerageDetailsSchema = Yup.object().shape({
    company_name: Yup.string().min(3, 'At least 3 characters').required('Company name is required'),
    company_contact: Yup.string().min(10, 'Please enter a valid number.').max(10, 'Please enter a valid number.'),
    company_fax: Yup.string().min(10, 'Please enter a valid fax number.').max(10, 'Please enter a valid fax number.'),
    company_email: Yup.string().email('Please enter a valid email address.'),
    company_address: Yup.string().min(10, 'At least 10 characters.'),
    company_address_2: Yup.string()
});
export const BrokerageDetails = forwardRef(({store, nextStep, onTabChange}, ref) => {
    const formRef = useRef();
    function updateWebsiteDetails(values, {setSubmitting}) {
        store.setAccountDetails({...store.accountDetails, ...values});
        setSubmitting(false);
    }

    return (
        <Formik
            initialValues={{
                company_name: store.accountDetails.company_name,
                company_contact: store.accountDetails.company_contact,
                company_fax: store.accountDetails.company_fax,
                company_email: store.accountDetails.company_email,
                company_address: store.accountDetails.company_address,
            }}
            validationSchema={brokerageDetailsSchema}
            onSubmit={updateWebsiteDetails}
            innerRef={ref}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} id="footer-contact-form" ref={formRef}>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label ">
                            Brokerage Name
                        </label>
                        <input
                            className="form-control "
                            placeholder="John Doe"
                            name="company_name"
                            value={values.company_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.company_name && touched.company_name && errors.company_name}</p>

                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label ">
                            Office Email
                        </label>
                        <input
                            className="form-control "
                            placeholder="john@example.com"
                            value={values.company_email}

                            name="company_email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.company_email && touched.company_email && errors.company_name}</p>

                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label ">
                            Office Phone
                        </label>
                        <input
                            className="form-control "
                            placeholder="4111111111"
                            value={values.company_contact}
                            type="number"
                            name="company_contact"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.company_contact && touched.company_contact && errors.company_contact}</p>

                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label ">
                            Office Fax
                        </label>
                        <input
                            className="form-control "
                            placeholder="4111111111"
                            value={values.company_fax}
                            type="number"
                            name="company_fax"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.company_fax && touched.company_fax && errors.company_fax}</p>

                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label ">
                            Office Address Line 1
                        </label>
                        <input
                            type="text"
                            className="form-control "
                            placeholder="Address..."
                            value={values.company_address}
                            name="company_address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.company_address && touched.company_address && errors.company_address}</p>
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label ">
                            Office Address Line 2
                        </label>
                        <input
                            type="text"
                            className="form-control "
                            placeholder="Address..."
                            value={values.company_address_2}
                            name="company_address_2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.company_address_2 && touched.company_address_2 && errors.company_address_2}</p>

                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label "> Brokerage Logo</label>
                        <UploadImage
                            type={'agent'}
                            image={store.accountDetails.company_logo}
                            width={200} onUpload={(imagePath) => store.setAccountDetails({
                            ...store.accountDetails,
                            company_logo: imagePath
                        })}/>
                    </div>

                </form>
            )}
        </Formik>
    )
});
