import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {verifyToken} from "./auth.api";
import {Loading} from "../../common/components/Loading";
import Button from "../../common/components/button";

export const EmailVerification = () => {
    const [loading, setLoading] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let [status, setStatus] = useState(0);
    let code = searchParams.get('code');
    useEffect(async function () {
        setLoading(true);
        if (code) {
            const response = await verifyToken(code);
            if (response && !response.api_status) {
                setStatus(1);
            } else {
                setStatus(2);
            }
        } else {
            setStatus(2);
        }
        setLoading(false);
    }, []);
    if (loading) {
        return <Loading/>
    }
    return <div className="py-my">
        <div className="container-fluid  my-5">
            <div className="container">
                <div className="row text-center p-5">

                    <div className="col-12 col-sm-8 col-md-10 mx-auto border rounded p-5">
                        <h4 className="text-primary underline text-center">Email Verification.</h4>
                        <h2 className="mt-4">
                            {
                                status === 1 ? 'Email successfully verified!' : 'Ops!! either your links is expired or invalid.'
                            }
                        </h2>
                        <Button href="/auth/login" type="outline" title="Login" className="my-4"/>

                    </div>
                </div>
            </div>
        </div>
    </div>
};
