import {action, makeObservable, observable} from "mobx";
import {StorageService as storage} from "./storage.service";
import {ToasterStore} from "./toaster.store";
import {accessTokenLogin} from "../pages/Authentication/auth.api";
import {AccountCreationStore} from "./accountCreation.store";


export class UserStoreData {
    user = false;
    isLoggedIn = false;
    loginChecking = true;

    constructor() {
        makeObservable(this,
            {
                user: observable,
                isLoggedIn: observable,
                loginChecking: observable,
                reset: action,
                setUser: action,
                checkIfLogin: action,
                clearOtherData: action,
                setLoginChecking: action,
            });
        let user = storage.getItem('user', 'json');
        if (user) {
            this.user = user;
            this.isLoggedIn = true;
        }
        this.checkIfLogin()
            .then(() => {
                this.loginChecking = false;
            }).catch(() => {
            this.loginChecking = false;
        });
    }

    setLoginChecking(status) {
        this.loginChecking = status;
    }

    reset() {
        this.user = false;
        this.isLoggedIn = false;
    }

    clearOtherData() {
        storage.remove('accountDetails');
        AccountCreationStore.reset();
        storage.remove('selectedPlan');
        ToasterStore.reset();
    }

    setUser(user) {
        if (user) {
            this.isLoggedIn = true;
        }
        this.user = user
    }

    async updateUserFromServer(){
        await accessTokenLogin();
    }

    setLogin(status) {
        if (status === true) {
            ToasterStore.setSuccessMessage('User successfully logged in.')
        }
        this.isLoggedIn = status;
    }

    async checkIfLogin() {
        if (storage.getItem('access_token')) {
            const result = await accessTokenLogin();
            if (result?.email) {
                this.isLoggedIn = true;
            }
        }
    }
    logout() {
        this.clearOtherData();
        storage.remove('user');
        storage.remove('access_token');
        this.reset();
        this.isLoggedIn = false;
    }
}

export const UserStore = new UserStoreData();
