import React, {createRef, useEffect} from 'react'
import {ContactUsImage} from "../../utils/images-export";
import Button from "../../common/components/button";
import {FAQ} from "../../common/components/Faq";
import {AccountCreationStore} from "../../stores/accountCreation.store";
import {Formik} from "formik";
import {useNavigate} from "react-router-dom";
import {contactUs} from "../Authentication/auth.api";
import {ToasterStore} from "../../stores/toaster.store";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";

const ContactSchema = Yup.object().shape({
    full_name: Yup.string()
        .min(2, 'Name should be at least 3 chars.')
        .max(50, 'Name should be at max 50 chars.')
        .required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required.'),
    phone: Yup.number().min(10, 'Invalid contact number.').required('Phone number is required.'),
    message: Yup.string().min(10, 'Message is too small.').required('Message is required.'),
});
export const Contact = () => {
    const _reCaptchaRef = createRef();
    const history = useNavigate();
    useEffect(function () {
        _reCaptchaRef.current.execute();
    }, []);

    const registerValues = {
        full_name: '',
        email: '',
        message: '',
        phone: '',
        recaptcha: ''
    };

    async function submitToRegister(values, {setSubmitting, resetForm}) {
        const response = await contactUs(values);
        if (!response.api_status) {
            ToasterStore.setSuccessMessage('Message sent successfully. We will contact you as early as possible.');
        } else {
            ToasterStore.setErrorMessage(response.message)
        }
        _reCaptchaRef.current.execute();
        setSubmitting(false);
        resetForm();
    }

    return <div className="py-my">
        <div className="container-fluid  mt-5">
            <div className="container">
                <div className="text-center">
                    <h3><span>AgentEdge Happy To Help you.</span></h3>
                    <p>We are here to help you with all your queries and concerns</p>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row  align-items-center">
                    <div className="col-12 col-md-6 pt-5 px-3">
                        <img src={ContactUsImage} className="img img-fluid mx-auto d-block" alt=""/>
                    </div>

                    <div className="col-12 col-md-6 px-3  mt-5 mt-md-0">
                        <h5>Get a Callback</h5>
                        <Formik
                            initialValues={registerValues}
                            validationSchema={ContactSchema}
                            onSubmit={submitToRegister}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  setSubmitting
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group mt-2">
                                        <label htmlFor="" className="form-label "> Name</label>
                                        <input className="form-control " placeholder="Full name" name="full_name"
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.full_name}
                                        />
                                        <p className="small text-danger pt-1">{errors.full_name && touched.full_name && errors.full_name}</p>
                                    </div>
                                    <div className="form-group mt-2">
                                        <label htmlFor="" className="form-label "> Email</label>
                                        <input className="form-control " placeholder="john@example.com" name="email"
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.email}/>
                                        <p className="small text-danger pt-1">{errors.email && touched.email && errors.email}</p>

                                    </div>
                                    <div className="form-group mt-2">
                                        <label htmlFor="" className="form-label "> Phone</label>
                                        <input className="form-control " placeholder="9876543210" name="phone"
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.phone}/>
                                        <p className="small text-danger pt-1">{errors.phone && touched.phone && errors.phone}</p>

                                    </div>
                                    <div className="form-group mt-2">
                                        <label htmlFor="" className="form-label "> Message</label>

                                        <textarea className="form-control " rows="5" placeholder="Message"
                                                  name="message"
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  value={values.message}
                                        />
                                        <p className="small text-danger pt-1">{errors.message && touched.message && errors.message}</p>

                                    </div>
                                    <ReCAPTCHA
                                        ref={_reCaptchaRef}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                        onChange={(value) => {
                                            setFieldValue("recaptcha", value);
                                            setSubmitting(false);
                                        }}
                                        size="invisible"
                                    />
                                    <Button title={"Submit"} type="solid"
                                            isSubmit={true}
                                            onClick={() => console.log('regiser')}
                                            className="mt-3 btn-block w-100" disabled={isSubmitting}/>
                                </form>

                            )}
                        </Formik>

                    </div>
                </div>
            </div>
            <FAQ list={AccountCreationStore.faq}/>
        </div>

    </div>
};
export default Contact;
