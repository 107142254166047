import {useState} from 'react'
import {ToasterStore} from "../stores/toaster.store";


function useCopyToClipboard() {
    const [copiedText, setCopiedText] = useState(null);

    const copy = async text => {
        if (!navigator?.clipboard) {
            ToasterStore.setSuccessMessage('Failed to copy');
            return false
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            ToasterStore.setSuccessMessage('Copied to clipboard');
            return true
        } catch (error) {
            setCopiedText(null);
            ToasterStore.setSuccessMessage('Failed to copy');
            return false
        }
    };

    return [copiedText, copy]
}

export default useCopyToClipboard
