import Button from "../../../common/components/button";
import React, {createRef, useState} from "react";
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import './index.scss'
import {Formik} from "formik";
import * as Yup from "yup";
import {ToasterStore} from "../../../stores/toaster.store";
import {UserStore} from "../../../stores/user.store";
import {getPaymentMethods, savePaymentMethod} from "../../Authentication/auth.api";
import {Loading} from "../../../common/components/Loading";
import {AccountCreationStore} from "../../../stores/accountCreation.store";

const billingDetailsSchema = Yup.object().shape({
    billing_name: Yup.string().min(3, 'At least 3 characters').required('Card holder name is required'),
    billing_address_1: Yup.string().min(5, 'Invalid Address').required('This field is required'),
    billing_address_2: Yup.string().min(3, 'Invalid Address').required('This field is required'),
    billing_city: Yup.string().min(2, 'Invalid City').required('City is required'),
    billing_state: Yup.string().min(2, 'Invalid Province').required('Province is required'),
    billing_zip: Yup.string().min(6, 'At least 6 characters.').required('Zip code is required'),

});
export const AddPaymentMethod = ({onClose, getPaymentMethods}) => {
    const stripe = useStripe();
    const elements = useElements();
    const ref = createRef();
    let defaultPlan = {};
    const [plan, setPlan] = useState(defaultPlan);
    const [loading, setLoading] = useState(false);

    const updateUserPaymentMethod = async (values, {setSubmitting, resetForm}) => {
        try {
            if (!stripe || !elements) {
                return;
            }
            const cardNumberElement = elements.getElement(CardElement);
            const result = await stripe.createToken(cardNumberElement, {
                currency: process.env.REACT_APP_STRIPE_CURRENCY,
                name: values.billing_name,
                address_line1: values.billing_address_1,
                address_line2: values.billing_address_2,
                address_city: values.billing_city,
                address_state: values.billing_state,
                address_zip: values.billing_zip,
                address_country: process.env.REACT_APP_STRIPE_CURRENCY
            });
            if (result && result.error && result.error.message) {
                return ToasterStore.setErrorMessage(result.error.message);
            }
            setLoading(true);
            const response = await savePaymentMethod({
                ...values,
                token: result.token.id
            });
            if (response.api_status) {
                ToasterStore.setErrorMessage('Something went wrong.');
            } else {
                getPaymentMethods();
                ToasterStore.setSuccessMessage('Card successfully Added.');
            }
            resetForm();
            onClose();
            setSubmitting(false);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setSubmitting(false);
        }
    };
    function onClosePanel() {
        ref.current.resetForm();
        onClose();
    }
    if (loading) {
        return <Loading/>
    }

    return <Formik
        initialValues={{
            billing_name: UserStore.user.name,
            billing_city: '',
            billing_state: '',
            billing_address_1: '',
            billing_address_2: '',
            billing_zip: '',
            plan_id: plan.plan_id,
            plan_duration: plan.plan_duration
        }}
        innerRef={ref}
        validationSchema={billingDetailsSchema}
        onSubmit={updateUserPaymentMethod}
    >
        {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} className="mb-3">
                <div className="row px-1">

                    <div className="form-group ">
                        <label htmlFor="" className="form-label ">
                            Billing Name
                        </label>
                        <input
                            className="form-control "
                            name="billing_name"
                            value={values.billing_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.billing_name && touched.billing_name && errors.billing_name}</p>
                    </div>
                    <div className="form-group ">
                        <label htmlFor="" className="form-label ">
                            Address Line 1
                        </label>
                        <input
                            className="form-control "
                            type={"text"}
                            value={values.billing_address_1}
                            name="billing_address_1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.billing_address_1 && touched.billing_address_1 && errors.billing_address_1}</p>
                    </div>
                    <div className="form-group ">
                        <label htmlFor="" className="form-label ">
                            Address Line 2
                        </label>
                        <input
                            type={"text"}
                            className="form-control "
                            value={values.billing_address_2}
                            name="billing_address_2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.billing_address_2 && touched.billing_address_2 && errors.billing_address_2}</p>
                    </div>
                    <div className="d-flex flex-column flex-md-row gap-0 gap-md-3">
                        <div className="form-group flex-grow-1 ">
                            <label htmlFor="" className="form-label ">
                                City
                            </label>
                            <input
                                className="form-control "
                                name="billing_city"
                                value={values.billing_city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <p className="small text-danger pt-1">{errors.billing_city && touched.billing_city && errors.billing_city}</p>
                        </div>
                        <div className="form-group flex-grow-1 ">
                            <label htmlFor="" className="form-label ">
                                Province
                            </label>
                            <select
                                className="form-select "
                                name="billing_state"
                                value={values.billing_state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value=""/>
                                {
                                    AccountCreationStore.provinces.map((province, i) => <option
                                        key={i}
                                        value={province.name}>{province.province_shortcode}</option>)
                                }
                            </select>

                            <p className="small text-danger pt-1">{errors.billing_state && touched.billing_state && errors.billing_state}</p>
                        </div>
                        <div className="form-group flex-grow-1">
                            <label htmlFor="" className="form-label ">
                                Zip Code
                            </label>
                            <input
                                className="form-control "
                                name="billing_zip"
                                value={values.billing_zip}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <p className="small text-danger pt-1">{errors.billing_zip && touched.billing_zip && errors.billing_zip}</p>
                        </div>
                    </div>
                  

                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label mb-3 ">
                            Card Details
                        </label>
                        {/*<PaymentElement className="strong"/>*/}
                        <CardElement options={{hidePostalCode: true}}/>
                    </div>
                    <div className="d-flex gap-3 justify-content-center">
                        <Button
                            title={"Save Card"}
                            type="solid"
                            className="mt-3 btn-block w-100"
                            isSubmit={true}
                        />
                        <Button
                            title={"Close"}
                            type="outline"
                            onClick={onClosePanel}
                            className="mt-3 btn-block w-100"
                            isSubmit={false}
                        />
                    </div>
                </div>

            </form>
        )}
    </Formik>
};
