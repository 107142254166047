import {Register} from "./Register";
import {SignIn} from "./Login";
import {ForgotPassword} from "./ForgotPassword";
import AccountCreation from "../AccountCreation";
import {Logout} from "./logout";
import {EmailVerification} from "./email-verification";

export const authRoutes = [
    {
        path: "account-setup",
        Component: AccountCreation,
        isPrivate: true,
        isAuthenticationRoute: false,
        isAuthenticationTrueRedirect: '/auth/login',
        seo: {
            title: 'Account Create'
        }
    },
    {
        path: "login",
        Component: SignIn,
        isAuthenticationRoute: true,
        loggedInDisallowed: true,
        isAuthenticationTrueRedirect: '/auth/account-setup',
        seo: {
            title: 'Sign In'
        }
    },
    {
        path: "verify-email",
        Component: EmailVerification,
        isAuthenticationRoute: true,
        loggedInDisallowed: true,
        isAuthenticationTrueRedirect: '/auth/account-setup',
        seo: {
            title: 'Email Verification'
        }
    },
    {
        path: "logout",
        Component: Logout,
        isAuthenticationRoute: true,
        isAuthenticationTrueRedirect: '/auth/logout',
        seo: {
            title: 'Logout'
        }
    },
    {
        path: "register",
        Component: Register,
        isAuthenticationTrueRedirect: '/auth/register',
        loggedInDisallowed: true,

        isAuthenticationRoute: true,
    },
    {
        path: "forgotPassword",
        Component: ForgotPassword,
        isAuthenticationTrueRedirect: '/auth/account-setup',
        loggedInDisallowed: true,
        isAuthenticationRoute: true,
    },
];
