import {UserStore} from "../../stores/user.store";
import {useNavigate} from 'react-router-dom';
import {useEffect} from "react";

export const Logout = () => {
    const navigate = useNavigate();
    useEffect(() => {
        UserStore.logout();
        navigate('/auth/login');
    }, []);
    return <>Logging out..</>;
};
