import {UploadAgentIcon, UploadLogoIcon} from "../../../utils/images-export";
import React, {createRef, useState} from "react";

export const UploadImage = ({
                                width,
                                type = 'graphic', classes = '', onUpload = () => {
    }, image = false
                            }) => {
    const [uploadedImage, setUploadedImage] = useState(image);
    const inputRef = createRef();

    function handleFile(e, key) {
        const reader = new FileReader();
        reader.onload = function (e) {
            onUpload(e.target.result);
            setUploadedImage(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0])
    }

    let graphic = UploadLogoIcon;
    if (type === 'agent') {
        graphic = UploadAgentIcon;
    }
    return <>
        <input type="file" ref={inputRef} className="d-none" onChange={handleFile}/>
        {
            !uploadedImage ?
                <img src={graphic} className={classes + ' d-block'} style={{cursor:'pointer'}} onClick={() => inputRef.current.click()}/>
                :
                <img src={uploadedImage} width={width || ''} style={{cursor:'pointer'}} className={classes + ' d-block img img-fluid'}
                     onClick={() => inputRef.current.click()}/>
        }
    </>
};
