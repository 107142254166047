import Design from "../../../assets/img/design-feature.png";
import {
    CRMFeatureImage, DesignFeatureImage,
    DigitalMarketingImage,
    IdxFeatureImage,
    SEOFeature,
    ServerFeature
} from "../../../utils/images-export";

export const FeaturesList = () => {
    return <>
        <div className="container-fluid theme-bg mt-5">
            <div className="container">
                <div className="section-heading text-center pt-5 pb-3">
                    <h3 className="underline">What <span>Differentiate</span> us from others.</h3>
                    <p>We design each website with the utmost perfection.</p>
                </div>
                <div className="row  feature-list  pb-4">
                    <div className="col-12 col-md-4 mb-3 feature  ">
                        <div className="text-center p-3 mb-3 bg-white rounded py-5 h-100">
                            <img src={ServerFeature} alt="" className="mb-3"/>
                            <h5 className="text-primary">Self Hosted Website</h5>
                            <p className="text-muted">Your website will be hosted on high-tech servers.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3 feature  ">
                        <div className="text-center p-3 mb-3 bg-white rounded py-5 h-100">
                            <img src={IdxFeatureImage} alt="" className="mb-3"/>
                            <h5 className="text-primary">Advanced Search System</h5>
                            <p className="text-muted">Let your clients search for their dream property by the preference of their location, suites, and more.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3 feature  ">
                        <div className="text-center p-3 mb-3 bg-white rounded py-5 h-100">
                            <img src={CRMFeatureImage} alt="" className="mb-3"/>
                            <h5 className="text-primary">Powerful CRM</h5>
                            <p className="text-muted">Get the freedom to manage all your leads, emails, and ads in one place.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3 feature  ">
                        <div className="text-center p-3 mb-3 bg-white rounded py-5 h-100">
                            <img src={SEOFeature} alt="" className="mb-3"/>
                            <h5 className="text-primary">SEO Friendly</h5>
                            <p className="text-muted">Let your clients search for their dream property by the preference of their location,
                                en suites, and more.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3 feature  ">
                        <div className="text-center p-3 mb-3 bg-white rounded py-5 h-100">
                            <img src={DigitalMarketingImage} alt="" className="mb-3"/>
                            <h5 className="text-primary">Digital Marketing</h5>
                            <p className="text-muted">The most advanced page builder allows you to create better page presentations,
                                making it easier and more enjoyable to navigate on the screen.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3 feature  ">
                        <div className="text-center p-3 mb-3 bg-white rounded py-5 h-100">
                            <img src={DesignFeatureImage} alt="" className="mb-3"/>
                            <h5 className="text-primary">Intuitive Navigation and Responsive Design</h5>
                            <p className="text-muted">Your website will be easy-to-use and work on all devices.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}
