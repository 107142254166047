import React from "react";
import {
    Map1SetupImage,
    Map2SetupImage,
    Map3SetupImage,
    Map4SetupImage,
    RecaptchaSetupImage
} from "../../utils/images-export";

export const GoogleKeysSetup = () => {
    return <>
        <div className="text-center">
            <h3><span>Setup Google Keys</span></h3>
            <p>You can follow these steps to setup All Google Keys</p>
        </div>
        <div className="step">
            <h5>1. Google Recaptcha</h5>
            <p>reCAPTCHA is a free service from Google that helps protect websites from spam and abuse. A “CAPTCHA” is a turing test to tell human and bots apart. It is easy for humans to solve, but hard for “bots” and other malicious software to figure out. By adding reCAPTCHA to a site, you can block automated software while helping your welcome users to enter with ease. </p>
            <div className="ps-4">
                <p >a. Visit <a target="_blank" href="https://www.google.com/recaptcha/admin/create">https://www.google.com/recaptcha/admin/create</a>
                </p>
                <p><img src={RecaptchaSetupImage} alt="" className="img img-fluid img-thumbnail"/></p>
                <p >b. Fill all the details</p>
                <p >c. Choose recaptcha v3</p>
                <p >d. Click Submit</p>
                <p >e. Copy Site key and secret key on AgentEdge => <b>Settings>Secret Keys>(Recaptcha Key, Recaptcha Secret)</b></p>
            </div>
        </div>
        <div className="step">
            <h5>2. Google Maps</h5>
            <p>A Google Maps API key is a personal code provided by Google to access Google Maps on your site.</p>
            <div className="ps-4">
                <p >a. Visit <a target="_blank" href="https://console.cloud.google.com/apis/library">https://console.cloud.google.com/apis/library?</a></p>
                <p >b. If Project not created, Create project by entering basic details</p>
                <p >c. Enable Maps JavaScript API</p>
                <p><img src={Map2SetupImage} alt="" className="img img-fluid img-thumbnail"/></p>
                <p >d. Enable Maps Embed API</p>
                <p><img src={Map3SetupImage} alt="" className="img img-fluid img-thumbnail"/></p>
                <p >e. Visit <a href="https://console.cloud.google.com/apis/credentials">https://console.cloud.google.com/apis/credentials</a></p>
                <p>f. Create credentials</p>
                <p><img src={Map4SetupImage} alt="" className="img img-fluid img-thumbnail"/></p>
                <p>g. Copy API Key</p>
                <p>h. You should also restrict the key only for your domain.</p>
                <p><img src={Map1SetupImage} alt="" className="img img-fluid img-thumbnail"/></p>
                <p>j. Select HTTP referrers (web sites) under application restrictions.</p>
                <p>k. Add domain name under website restrictions.</p>
                <p>l. Save the settings.</p>
                <p>m. Copy Site key and secret key on AgentEdge => <b>Settings>Secret Keys>(Google Maps Key)</b></p>
            </div>
        </div>
    </>
};
