import React, {useState} from 'react';
import {AccountCreationStore} from "../../../stores/accountCreation.store";
import {DoneIcon, Plan1, Plan2} from "../../../utils/images-export";
import Button from "../button";
import {useNavigate} from "react-router-dom";

export const PricingInfo = ({redirect = true, onSelect = () => {}}) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [pricing, setPricing] = useState(12);

    const getDiscountedValue = ({plan, discount}) => {
        return (plan * ((1 - discount / 100))).toFixed(0);
    };
    const register = (plan) => {
        AccountCreationStore.setSelectedPlan(plan);
        onSelect();
        return navigate('/auth/register')
    };
    return <>
        <div className="row pricingInfo  justify-content-center">
            <div className="col-12 col-lg-8 col-lg-6">
                <ul className="nav nav-pills nav-fill mb-3 ">
                    <li className="nav-item">
                        <a className={"nav-link " + (pricing === 12 && 'active')}
                           aria-current="page"
                           href="#"
                           onClick={() => setPricing(12)}>Yearly</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + (pricing === 1 && 'active')}
                           onClick={() => setPricing(1)}
                           href="#">Monthly</a>
                    </li>
                </ul>
            </div>
        </div>
        <div className="row justify-content-center">
            {
                AccountCreationStore.plans && AccountCreationStore.plans.length > 0 &&
                AccountCreationStore.plans.map((plan, key) => <>
                    <div className="col-12 col-lg-6  mb-3">
                        <div className="card">
                            <div className="card-body">
                                <p className="small mb-0">{plan.plan_name}</p>
                                <h2 className="text-primary card-title d-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-column">
                                        {
                                            plan.discount[pricing] > 0 &&
                                            <span style={{fontSize: '1rem'}}
                                                  className="text-success">{plan.discount[pricing]}% Discount</span>
                                        }
                                        {
                                            plan.discount[pricing] > 0 &&
                                            <small
                                                style={{fontSize: '1rem',}}><strike>CAD {plan.plan_price}/Month</strike></small>
                                        }
                                        <span>CAD {plan.discounted_price[pricing]}/Month</span>
                                    </div>
                                    <img src={key === 0 ? Plan2 : Plan1} alt="" width={100}/>
                                </h2>
                                <p><small><small><i> + Setup fee: {plan.setup_fee}</i></small></small></p>
                                <div className="d-flex flex-column  mt-3">
                                    {
                                        plan.plan_features.map((featureHead, head) => (
                                            <div key={'feature-' + featureHead.title}>
                                                <h6 className="text-muted">{featureHead.title}</h6>
                                                {
                                                    featureHead.features.map((feature, i) => (
                                                        <p className="d-flex gap-2"
                                                           key={'feature-' + featureHead.title + '-' + i}>
                                                            <img
                                                                src={DoneIcon} alt=""/>{feature}
                                                        </p>
                                                    ))
                                                }
                                            </div>

                                        ))
                                    }
                                </div>
                                <Button onClick={() => register({
                                    plan_id: plan.plan_id,
                                    price: plan.plan_price,
                                    discounted_price: plan.discounted_price[pricing],
                                    duration: pricing
                                })}
                                        href={redirect ? "/auth/register" : ''}
                                        className="btn-lg w-100 btn-lg"
                                        title="Get Started"/>
                            </div>
                        </div>
                    </div>
                </>)

            }
        </div>
    </>
};
