import React from 'react';
import './button.scss';
import {Link} from "react-router-dom";

const Button = ({
                    isLink,
                    isImage,
                    isSubmit, title, type, className = '', size = '', href = "", target, onClick = () => {
    },
    ...args
                }) => {
    let btnType = 'btn-success';
    switch (type) {
        case 'outline':
            btnType = 'btn-outline-success';
            break;
        case 'solid':
            btnType = 'btn-success';
            break;
        default:
            break;
    }
    if (isImage) {
        return <Link to={href}>
            {title}
        </Link>
    }
    if (isLink) {
        return <Link to={href} onClick={onClick}  className={"nav-link " + className} {...args}>
            {title}
            </Link>
    }
    if (isSubmit) {
        return <button type="submit"
                       className={"btn " + btnType + ' ' + className + ' ' + size}  {...args}>{title}</button>
    }
    if (target) {
        return <a onClick={onClick} href={href} target={"target"}
                  className={"btn " + btnType + ' ' + className + ' ' + size}>{title}</a>
    }
    return <Link to={href}  className={"btn " + btnType + ' ' + className + ' ' + size}>
        <span className="d-block" onClick={onClick} {...args}>{title}</span>
    </Link>
};
export default Button;
