import Home from "../pages/home";
import Contact from "../pages/contact";
import {Designs} from "../pages/Designs";
import Pricing from "../pages/pricing/pricing";
import {AuthContainer} from "../pages/Authentication/AuthContainer/AuthContainer";
import {authRoutes} from "../pages/Authentication/auth.routes";
import {DashboardWrapper} from "../pages/Dashboard";
import {dashboardRoutes} from "../pages/Dashboard/dashboardRoutes";
import {Policies} from "../pages/StaticPages/policies";
import {HowToSetupList} from "../pages/HowToSetup";
import {HowToContainer} from "../pages/HowToSetup/howto.container";
import {GoogleKeysSetup} from "../pages/HowToSetup/GoogleKeysSetup";
import {SetupNameServer} from "../pages/HowToSetup/setup-nameservers";
import {FAQPage} from "../pages/FAQ";
import {NotFound} from "../pages/NotFound";
import {AgentAgreement} from "../pages/HowToSetup/agent-agreement";
import {About} from "../pages/About";
import {ReferAndEarn} from "../pages/Dashboard/Referral";
import { Blog } from "../pages/Blog";
import { BlogPage } from "../pages/Blog/BlogPage";


const routes = [
  {
    path: "",
    Component: Home,
  },
  {
    path: "contact",
    Component: Contact,
  },
  {
    path: "designs",
    Component: Designs,
  },
  {
    path: "blog",
    Component: Blog,
  },
  {
    path: "blog/:blog_id",
    Component: BlogPage,
  },
  {
    path: "pricing",
    Component: Pricing,
  },
  {
    path: "about",
    Component: About,
  },
  {
    path: 'auth',
    Component: AuthContainer,
    subRoutes: authRoutes
  },
  {
    path: 'dashboard',
    Component: DashboardWrapper,
    subRoutes: dashboardRoutes
  },
  {
    path: 'policies/:policyType',
    Component: Policies,
  },
  {
    path: 'how-to-setup/list',
    Component: HowToSetupList,
  },
  {
    path: 'how-to-setup',
    Component: HowToContainer,
    subRoutes: [
      {
        path: '/how-to-setup/google-keys',
        Component: GoogleKeysSetup,
      },
      {
        path: '/how-to-setup/name-server',
        Component: SetupNameServer,
      },
      {
        path: '/how-to-setup/agent-agreement',
        Component: AgentAgreement,
      }
    ]
  },
  {
    path: 'faq',
    Component: FAQPage,
  },
  {
    path: '*',
    Component: NotFound
  }
];
export default routes;
