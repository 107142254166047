import Button from "../../common/components/button";
import {AccountCreationStore} from "../../stores/accountCreation.store";
import {observer} from "mobx-react";
import {useState} from "react";
import {Pagination} from "react-bootstrap";

export const Designs = observer(() => {
    const offset = 3;
    const [page, setPage] = useState(1);

    return <>
        <div className="container mt-5 text-center ">
            <h3 className="underline">Our <span>Aesthetic Real Estate Website</span> Design Options</h3>
            <p>Here are our recently added website templates's which could be your identity. </p>
            <div className="row mt-5">
                {
                    AccountCreationStore.websitesList.slice((page - 1) * offset, (page * offset)).map((website, index) => (
                        <div className="col-12 col-md-4 feature  mb-3">
                            <div className="text-center p-3 mb-3 card">
                                <img src={website.screenshot} alt="" className="mb-3 img img-fluid"/>
                                <h5 className="text-primary card-title pb-0 mb-0">{website.agent_name}</h5>
                                <p className="pt-0"><small><i>Brokerage: {website.brokerage}</i></small></p>
                                <div className="d-flex flex-column flex-md-row gap-3">
                                    <Button href={website.base_url} target="_blank" className="btn-sm w-100"
                                            type="outline" title="View Website"/>
                                    <Button href="/auth/register" className="btn-sm w-100" type="solid"
                                            title="Get Template"/>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <nav aria-label="Demo websites Pagination" className="d-flex justify-content-center">
                <Pagination>
                    <Pagination.First onClick={() => setPage(1)} disabled={page === 1}/>
                    {
                        Array(Math.ceil(AccountCreationStore.websitesList.length / offset)).fill('x').map((num, i) =>
                            <Pagination.Item active={i+1 === page} key={i} onClick={() => setPage(i + 1)}>{i + 1}</Pagination.Item>
                        )
                    }
                    <Pagination.Last onClick={() => setPage(Math.ceil(AccountCreationStore.websitesList.length / offset))}
                                     disabled={AccountCreationStore.websitesList.length === page}/>
                </Pagination>
            </nav>
        </div>

    </>
});
