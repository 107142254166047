import React, {useEffect, useState} from "react";
import {getOverdue, getTransactions, payOverduePayment} from "../../Authentication/auth.api";
import Moment from "react-moment";
import {Loading} from "../../../common/components/Loading";
import {PaymentConfirmationModal} from "./PaymentConfirmation.modal";
import {ToasterStore} from "../../../stores/toaster.store";

export const Billing = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paying, setPaying] = useState(false);
    let [overDue, setOverdue] = useState({amount: 0, plan_end_date: '', due_months: ''});
    useEffect(async () => {
        setLoading(true);
        setTransactions(await getTransactions());
        setLoading(false);
        let overdueDetails = await getOverdue();
        if (overdueDetails && overdueDetails.amount) {
            setOverdue(overdueDetails)
        }
    }, []);
    if (loading) {
        return <Loading/>;
    }


    async function payNow() {
        setPaying(true);
        const response = await payOverduePayment();
        setPaying(false);
        document.querySelector('.paymentModalClose').click();
        if (response) {
            ToasterStore.setSuccessMessage('Payment done successfully.');
        } else {
            ToasterStore.setErrorMessage('Something went wrong.');
        }
        setTransactions(await getTransactions());
        let overdueDetails = await getOverdue();
        if (overdueDetails && overdueDetails.amount) {
            setOverdue(overdueDetails)
        } else {
            setOverdue({amount: 0, plan_end_date: '', due_months: ''})
        }
    }

    return <>
        <div>
            <h4 className="text-center text-primary underline">Billing</h4>
            {
                overDue.amount > 0 && <div className="due d-flex flex-row justify-content-between mb-3">
                    <div>
                        <p className="mb-0 pb-0">Payment Date</p>
                        <h5 className="mb-0 mt-2 py-0">Due date: <Moment
                            format="MM/DD/YYYY">{overDue.plan_end_date}</Moment></h5>
                    </div>
                    <div className="d-flex flex-column justify-content-end align-items-end gap-2">
                        <p className="my-0 py-0">Payment Due</p>
                        <h5 className="my-0  py-0">{overDue.amount ? 'CAD ' + overDue.amount : '-'}</h5>
                        {
                            overDue.amount > 0 &&
                            <button className="btn btn-outline-success " type="button" data-bs-toggle="modal"
                                    id={"payNowBtn"}
                                    data-bs-target="#paymentModal">Pay Now
                            </button>
                        }
                    </div>
                </div>
            }

            <h5 className="text-muted">Transactions</h5>
            {
                transactions.length === 0 ? <p className="text-center">No transactions available.</p> :
                    <>
                        <div className="table-responsive">
                            <table className="table-striped table">
                                <thead>
                                <tr>
                                    <td>Transaction Id</td>
                                    <td>Payment</td>
                                    <td>Date</td>
                                    <td>Card</td>
                                    <td>Status</td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    transactions.map((transaction, key) => (
                                        <tr key={key}>
                                            <td>{transaction.transaction_id}</td>
                                            <td>CAD {transaction.amount}</td>
                                            <td><Moment format={"MM-DD-YYYY hh:mm A"}>{transaction.created}</Moment></td>
                                            <td>{(transaction.card_last4 && 'XXXX ' + transaction.card_last4 ) || '-'}</td>

                                            <td>{transaction.status ?
                                                <span
                                                    className="bg-success text-light rounded-pill small px-2 py-1">Success</span> :
                                                <span
                                                    className="bg-danger text-light rounded-pill small px-2 py-1">Failed</span>
                                            }</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>

                    </>
            }
        </div>
        <PaymentConfirmationModal amount={overDue.amount} paying={paying} onPayNow={payNow}/>
    </>
};
