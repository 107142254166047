import Button from "../../../common/components/button";
import React, {createRef, useEffect} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {ToasterStore} from "../../../stores/toaster.store";
import {forgotPassword} from "../auth.api";
import ReCAPTCHA from "react-google-recaptcha";

const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    recaptcha: Yup.string().required(),
});

export const ForgotPassword = () => {
    const _reCaptchaRef = createRef();
    useEffect(function () {
        _reCaptchaRef.current.execute();

    }, []);
    const sendResetPasswordLink = (values, {setSubmitting, resetForm}) => {
        forgotPassword({email: values.email, recaptcha: values.recaptcha})
            .then(response => {
                if (!response.api_status) {
                    ToasterStore.setSuccessMessage('Password reset link sent to your email address. Please check to continue.')
                } else {
                    ToasterStore.setErrorMessage(response.message)
                }
                resetForm();
                setSubmitting(false);
            })
            .catch(e => {
                resetForm();
                setSubmitting(false);
            });


    };
    return (
        <div className="py-my">
            <div className="container-fluid  ">
                <div className="container">
                    <div className="row  p-5">

                        <div className="col-12 col-lg-6 mx-auto border rounded p-5">
                            <h4 className="underline text-center text-primary">Forgot Password</h4>
                            <Formik
                                initialValues={{email: '', recaptcha: ''}}
                                validationSchema={forgetPasswordSchema}
                                onSubmit={sendResetPasswordLink}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                      setFieldValue,
                                      setSubmitting
                                  }) => (<form onSubmit={handleSubmit}>
                                        <div className="form-group mt-2">
                                            <label htmlFor="" className="form-label "> Email</label>
                                            <input className="form-control " placeholder="john@example.com" name="email"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.email}/>
                                            <p className="small text-danger pt-1">{errors.email && touched.email && errors.email}</p>
                                        </div>
                                        <ReCAPTCHA
                                            ref={_reCaptchaRef}
                                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                            onChange={(value) => {
                                                console.log("$$$$", isSubmitting, value);
                                                setFieldValue("recaptcha", value);
                                                setSubmitting(false);
                                            }}
                                            size="invisible"
                                        />
                                        <Button title={"Reset Password"} type="solid"
                                                isSubmit={true}
                                                className="mt-3 btn-block w-100" disabled={isSubmitting}/>
                                        <Button title={"Login"} type="outline" href="/auth/login"
                                                className="mt-3 btn-block w-100"/>

                                    </form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
