import { useState, useEffect } from "react";
import { getAPI } from "../pages/Authentication/auth.api";

export const useFetchData = (url) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  const limit = 10;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await getAPI(
        `${url}?limit=${limit}&offset=${(page - 1) * limit}`
      );
      console.log(result);
      setLoading(false);
      setData([...result.data]);
      setTotalCount(result.total_count);
      console.log(result.total_count - 1, limit * page);
      setLoadMore(result.total_count - 1 > limit * page);
    };

    fetchData();
  }, [url, page]);

  const loadNext = () => {
    if (data.length < totalCount) {
      setPage(page + 1);
      setLoadMore(totalCount - 1 > limit * page);
    }
  };
  const loadPrev = () => {
    if (data.length < totalCount) {
      setPage(page - 1);
      setLoadMore(totalCount - 1 > limit * page);
    }
  };

  return {
    data,
    totalCount,
    page,
    loadMore,
    loadNext: loadMore && loadNext,
    loading,
    loadPrev: page !== 1 && loadPrev,
  };
};
