import React from 'react';
import Banner from "../../assets/img/about-us.png";
import './about.scss';
import {ParallaxBox} from "../../common/components/ParallaxBox";

export const About = () => {
    return <div className="container-fluid mt-5  px-0 about-page">
        <div className="container">
            <div className="text-center">
            <h3 className="underline text-center"><span>About Us</span></h3>
            <p>Learn About Our Company's Story</p>

            </div>
            <div className="row d-flex align-items-center">
                <div className="col-12 order-0 order-lg-1 col-md-6  ">
                    <h3 className={"mb-4"}><>We believe <span>Better</span> Agents <span>Better</span> Websites</>
                    </h3>
                    <h5>Our Mission</h5>
                    <p>
                     We are believe that every Real Estate Agent have right to own a advanced, modern, lead generating website. We are on mission to provide a
                     best user experience to our Real Estate Agents as well as their customers. That's why created AgentEdge which will help you to grew your Real Estate Business everyday.
                    </p>

                </div>
                <div className="col-12 order-0 order-lg-1 col-md-6  ">
                    <img src={Banner} className="img img-fluid"/>
                </div>
            </div>
        </div>
        <ParallaxBox>
            <div className="row d-flex align-items-center h-100 text-center gap-3 gap-lg-0">
                <div className="col-12 col-lg-3">
                    <d className="d-flex flex-column">
                        <h4 className="display-5">15+</h4>
                        <p className="h3 text-white">Websites</p>
                    </d>
                </div>
                <div className="col-12 col-lg-3">
                    <d className="d-flex flex-column">
                        <h4 className="display-5">4.7+</h4>
                        <p className="h3 text-white">Ratings</p>
                    </d>
                </div>
                <div className="col-12 col-lg-3">
                    <d className="d-flex flex-column">
                        <h4 className="display-5">10+</h4>
                        <p className="h3 text-white">Designs</p>
                    </d>
                </div>
                <div className="col-12 col-lg-3">
                    <d className="d-flex flex-column">
                        <h4 className="display-5">1k+</h4>
                        <p className="h3 text-white">Leads Generated</p>
                    </d>
                </div>
            </div>
        </ParallaxBox>

    </div>
};
