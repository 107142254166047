import {AccountCreatedImage, StarIcon, SuccessSignupImage} from "../../utils/images-export";
import React from "react";
import Button from "../../common/components/button";

export const AccountSuccess = () => {
    return <div className="container">
        <div className="row pt-4">
            <div className="col-12  d-flex flex-column justify-content-center text-center">
                <h3 className="text-center underline text-primary">Account created successfully.</h3>
                <img src={AccountCreatedImage} className="img img-fluid mx-auto d-none d-lg-block" alt=""/>
                <div>
                    <Button href="/dashboard/home" type="outline" title="Go to Dashboard"  className="my-4"/>
                </div>
            </div>
        </div>
    </div>
};
