import { useParams } from "react-router-dom";
import BlogDetails from "../../../common/components/BlogDetails";
import { Loading } from "../../../common/components/Loading";
import { useDetailsData } from "../../../utils/detailsApi";

const blog = {
  title: "Example Blog Title",
  description: "Example blog description goes here.",
  publishedDate: "March 9, 2023",
  image: "https://example.com/image.jpg",
  recentBlogs: [
    {
      id: 1,
      title: "Recent Blog 1",
      link: "https://example.com/recent-blog-1",
    },
    {
      id: 2,
      title: "Recent Blog 2",
      link: "https://example.com/recent-blog-2",
    },
    {
      id: 3,
      title: "Recent Blog 3",
      link: "https://example.com/recent-blog-3",
    },
  ],
};

export const BlogPage = ({ matchs }) => {
  let { blog_id } = useParams();

  const { data, error, loading } = useDetailsData("/general/blog/" + blog_id);
  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="py-my">
        <div className="container-fluid  mt-5">
          <div className="container">
            <div className="text-center mb-5">
              <h3 className="underline">
                <span>{data.post_title}</span>
              </h3>
            </div>

            <BlogDetails
              description={data.post_description}
              image={data.post_main_image}
              publishedDate={data.created}
              recentBlogs={data.recentBlog}
            />
          </div>
        </div>
      </div>
    </>
  );
};
