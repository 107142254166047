import {Link, useMatch, useResolvedPath} from "react-router-dom";

const NavLink = ({children, to, ...props}) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({path: resolved.pathname, end: true});

    return (
            <Link
                to={to}
                {...props}
                className={match ? "active " + (props.className || '') : (props.className || '')}
            >
                {children}
            </Link>
    );
}

export default NavLink
