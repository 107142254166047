import Button from "../button";
import {AccountCreationStore} from "../../../stores/accountCreation.store";

export const DemoWebsites = () => {
    return <div className="container-fluid theme-bg mt-5 text-center pt-5">
        <div className="container">
            <h3 className="underline">Our <span>Happy Clients</span></h3>
            <p>Here are our recently added website demo's which are running. </p>
            <div className="row">
                {
                    AccountCreationStore.websitesList.slice(0, 5).map((website, key) => <div
                        className="col-12 col-md-4 feature  mb-3">
                        <div className="text-center p-3 mb-3 card">
                            <img src={website.screenshot} alt="" className="mb-3 img img-fluid"/>
                            <h5 className="text-primary card-title pb-0 mb-0">{website.agent_name}</h5>
                            <p className="pt-0"><small><i>Brokerage: {website.brokerage}</i></small></p>
                            <Button href={website.base_url} target="_blank" className="btn-sm w-100" type="outline"
                                    title="View Website"/>
                        </div>
                    </div>)
                }
            </div>
        </div>
    </div>
};
