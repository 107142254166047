import Button from "../button";
import React, {createRef, useEffect} from "react";
import {Formik} from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import {contactUs} from "../../../pages/Authentication/auth.api";
import {ToasterStore} from "../../../stores/toaster.store";

const footerContactSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    message: Yup.string().min(10, 'Message too short').required('Message is required'),
    recaptcha: Yup.string().required('Recaptcha required.')
});
export const ContactQuery = () => {
    const _reCaptchaRef = createRef();
    useEffect(function () {
        _reCaptchaRef.current.execute();
    }, []);

    async function submitContact(values, {setSubmitting, resetForm}) {
        const response = await contactUs({
            message: 'Plans and pricing contact form',
            email: values.email,
            phone: '-',
            full_name: 'Anonymous',
            recaptcha: values.recaptcha
        });
        if (!response.api_status) {
            ToasterStore.setSuccessMessage('Message sent successfully. We will contact you as early as possible.');
        } else {
            ToasterStore.setErrorMessage(response.message)
        }
        _reCaptchaRef.current.execute();
        setSubmitting(false);
        resetForm();
    }

    return <div className="container-fluid mt-5 theme-bg mb-3 ">
        <div className="row py-5 text-center">
            <div className="col-12 col-sm-12 col-lg-6 mx-auto">
                <h3 className="underline">Have any <span>Query</span></h3>
                <p>We are happy to help you on all your queries</p>
                <div className="row">
                    <div className="col-12 mx-auto">
                        <Formik
                            initialValues={{email: '', message: ''}}
                            validationSchema={footerContactSchema}
                            onSubmit={submitContact}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  setSubmitting
                              }) => (
                                <form onSubmit={handleSubmit} className="text-start">
                                    <div className="form-group mt-2">
                                        <input className="form-control " placeholder="john@example.com"
                                               name="email"
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               value={values.email}
                                        />
                                        <p className="small text-danger pt-1">{errors.email && touched.email && errors.email}</p>

                                    </div>
                                    <div className="form-group ">
                                        <textarea className="form-control " placeholder="Your message..."
                                                  name="message"
                                                  rows={5}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  value={values.message}
                                        />
                                        <p className="small text-danger pt-1">{errors.message && touched.message && errors.message}</p>

                                    </div>
                                    <ReCAPTCHA
                                        ref={_reCaptchaRef}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                        onChange={(value) => {
                                            setFieldValue("recaptcha", value);
                                            setSubmitting(false);
                                        }}
                                        size="invisible"
                                    />
                                    <Button title={"Get a Callback"} type="solid"
                                            isSubmit={true}
                                            className=" btn-block w-100" disabled={isSubmitting}/>
                                </form>
                            )}
                        </Formik>


                    </div>
                </div>
            </div>
        </div>
    </div>
}
