import React from "react";
import {AccountCreationStore} from "../../stores/accountCreation.store";
import NavLink from "../../common/components/NavLink";

export const SetupNameServer = () => {
    return <>
        <div className="col-12 col-md-8">
            <div className="text-center">
                <h3><span>Complete Your nameserver setup</span></h3>
                <p>Step by step guide to update your nameserver.</p>
            </div>
            <div className="step">
                <p>1. Login to your registrar account.</p>
                <p>2. Select your domain.</p>
                <p>3. Go to DNS section</p>
                <p>4. Update the DNS records with AgentEdge DNS(Shown in dashboard).</p>
                <p>5. Go to AgentEdge dashboard. </p>
                <p>6. Click on DNS Check button.</p>
            </div>
        </div>
    </>
};
