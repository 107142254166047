import React, {forwardRef} from "react";
import {UploadImage} from "../../common/components/UploadImage";
import Button from "../../common/components/button";
import {Formik} from "formik";
import * as Yup from "yup";

const agentDetailsSchema = Yup.object().shape({
    salesman_name: Yup.string().min(3, 'At least 3 characters').required('Agent name is required'),
    salesman_contact: Yup.string().min(10, 'Please enter a valid number. Only numbers accepted.').max(10, 'Please enter a valid number. Only numbers accepted.'),
    salesman_email: Yup.string().email('Please enter a valid email address.'),
    salesman_designation: Yup.string().min(3, 'At least 3 characters.')
});
export const AgentDetails = forwardRef(({store, nextStep}, ref) => {
    function updateWebsiteDetails(values, {setSubmitting}) {
        console.log(values, 'val;ues', store.accountDetails)
        store.setAccountDetails({...store.accountDetails, ...values});
        setSubmitting(false);
    }

    return (
        <Formik
            initialValues={{
                salesman_name: store.accountDetails.salesman_name,
                salesman_contact: store.accountDetails.salesman_contact,
                salesman_email: store.accountDetails.salesman_email,
                salesman_designation: store.accountDetails.salesman_designation,
            }}
            validationSchema={agentDetailsSchema}
            innerRef={ref}
            onSubmit={updateWebsiteDetails}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} >
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label ">
                            Agent Name
                        </label>
                        <input
                            className="form-control "
                            placeholder="John Doe"
                            name="salesman_name"
                            value={values.salesman_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.salesman_name && touched.salesman_name && errors.salesman_name}</p>

                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label ">
                            Agent Email
                        </label>
                        <input
                            className="form-control "
                            placeholder="john@example.com"
                            value={values.salesman_email}

                            name="salesman_email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.salesman_email && touched.salesman_email && errors.salesman_name}</p>

                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label ">
                            Agent Phone
                        </label>
                        <input
                            className="form-control "
                            placeholder="4111111111"
                            value={values.salesman_contact}

                            type="number"
                            name="salesman_contact"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.salesman_contact && touched.salesman_contact && errors.salesman_contact}</p>

                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label ">
                            Agent Designation
                        </label>
                        <input
                            className="form-control "
                            placeholder="Realtor"
                            value={values.salesman_designation}

                            type="text"
                            name="salesman_designation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <p className="small text-danger pt-1">{errors.salesman_designation && touched.salesman_designation && errors.salesman_designation}</p>

                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label "> Agent Image</label>
                        <UploadImage
                            type={'agent'}
                            image={store.accountDetails.salesman_image}
                            width={200} onUpload={(imagePath) => store.setAccountDetails({
                            ...store.accountDetails,
                            salesman_image: imagePath
                        })}/>
                    </div>

                </form>
            )}
        </Formik>

    )
});
