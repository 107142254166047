import './index.scss';
import {useState} from "react";

export const Testimonials = ({testimonials}) => {
    const [active, setActive] = useState(0);

    function nextSlide() {
        if (active === testimonials.length - 1) {
            return;
        }
        setActive(active + 1);
    }

    function previousSlide() {
        if (active === 0) {
            return;
        }
        setActive(active - 1);
    }

    function getTranslate(index) {
        if (index === active) {
            return 0
        }
        return ((index - active) * 1800) + 'px'

    }

    return <>
        {
            testimonials && testimonials.length > 0 && <div className="container">
                <div className="row my-5">
                    <div className="col-12 text-center testimonial-container">
                        <h3 className="text-primary underline ">Testimonials</h3>
                        <p>Our clients success stories..</p>
                        <div className="testimonials-list mt-5">
                            {
                                testimonials?.map((testimonial, index) => (
                                    <div className={"testimonial px-5 " + (index === active ? 'active' : '')}
                                         key={index}
                                         translate={getTranslate(index)}>
                                        <p><i>{testimonial.review}</i></p>
                                        <img
                                            src={testimonial.reviewer_image}
                                            alt="" className="img img-fluid rounded-pill" width={50}/>
                                        <h6 className="text-primary mb-0 mt-3">{testimonial.name}<small
                                            className="text-secondary"> ({testimonial.designation})</small></h6>
                                        <p className="mt-0"><small
                                            className="text-secondary">{testimonial.website}</small></p>
                                    </div>
                                ))
                            }
                            {
                                active > 0 && <div className="arrow arrow-prev" onClick={previousSlide}/>

                            }
                            {
                                active < testimonials?.length - 1 &&
                                <div className="arrow arrow-next" onClick={nextSlide}/>
                            }

                        </div>
                    </div>
                    {/*
            <div className="col-6">
                <img src={SuccessSignupImage} alt="" className="img img-fluid"/>
            </div>*/}
                </div>
            </div>
        }
    </>
};
