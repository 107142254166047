import React, {createRef, useEffect, useState} from "react";
import {AgentDetails} from "./AgentDetails";
import {PaymentDetails} from "./PaymentDetails";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "./index.scss";
import {Loading} from "../../common/components/Loading";
import {WebsiteDetails} from "./WebsiteDetails";
import {observer} from "mobx-react-lite";
import {AccountCreationStore} from "../../stores/accountCreation.store";
import {BrokerageDetails} from "./Brokerage";
import {createStripeIntent} from "../Authentication/auth.api";
import Button from "../../common/components/button";
import {AccountSuccess} from "./AccountSuccess";
import {UserStore} from "../../stores/user.store";
import {useNavigate} from 'react-router-dom';
import {SelectPlan} from "./SelectPlan";
import {TemplateDetails} from "./TemplateDetails";
import {AccountCreationProvider} from "./AccountCreation.context";

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHER_KEY
);

const AccountCreation = (
    observer(() => {
        const [stripeSecret, setStripeSecret] = useState(false);
        const [currentPage, setCurrentPage] = useState("template");
        const [success, setSuccess] = useState();
        const navigate = useNavigate();
        const tabRef = createRef();
        const [steps, setSteps] = useState([
            {
                step: 6,
                type: "payment",
                Component: PaymentDetails,
            },
            {
                step: 2,
                type: "website",
                Component: WebsiteDetails,
            },
            {
                step: 1,
                type: "template",
                Component: TemplateDetails,
            },
            {
                step: 3,
                type: "agent",
                Component: AgentDetails,
            },
            {
                step: 4,
                type: "brokerage",
                Component: BrokerageDetails,
            },
            {
                step: 5,
                type: "plan",
                Component: SelectPlan,
            },

        ]);
        const nextStep = () => {
            let currentStep = steps.find((d) => d.type === currentPage).step;
            updateCurrentTab(steps.find((d) => d.step === currentStep + 1).type)
        };
        const CurrentComponent = () => steps[currentPage].Component;
        useEffect(function () {
            return () => {
                AccountCreationStore.setAccountDetails({});
            }
        }, []);

        useEffect(function () {
            if (UserStore.user && UserStore.user.website && UserStore.user.website.base_url) {
               navigate('/dashboard/home');
            }
        }, []);

        useEffect(function () {
            createStripeIntent().then((response) => {
                setStripeSecret(response.client_secret)
            }).catch(e => {
            });
        }, []);

        function updateCurrentTab(newTab) {
            let currentStep = steps.find((d) => d.type === currentPage).step;
            let newStep = steps.find((d) => d.type === newTab).step;
            if (currentPage === 'payment') {
                if (tabRef.current) {
                    AccountCreationStore.setAccountDetails({...AccountCreationStore.accountDetails, ...tabRef.current.values});
                }
                return setCurrentPage(newTab)
            }
            if (!tabRef.current) {
                return setCurrentPage(newTab)
            }
            let isValid = tabRef.current.isValid;
            let formValues = tabRef.current.values;
            tabRef.current.submitForm().then(() => {
                AccountCreationStore.setAccountDetails({...AccountCreationStore.accountDetails, ...formValues});
                if (newStep > currentStep) {
                    if (isValid) {
                        setCurrentPage(newTab)
                    }
                } else {
                    setCurrentPage(newTab)
                }
            });

        }

        return (
            <>
                {
                    !success ?
                        <AccountCreationProvider>
                            <div className="py-my">
                                <div className="container-fluid  my-5">
                                    <div className="container">
                                        <div className="text-center">
                                            <h3 className="underline">
                                                <span>Provide more details</span>
                                            </h3>
                                            <p>
                                                Please tell a bit about you so that we can personalize your on
                                                boarding experience.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="container mt-5">
                                        <div className="row">
                                            <div className="col-12 col-md-10 mx-auto col-lg-9">
                                                <ul className="nav nav-pills nav-fill mb-3 ">
                                                    <li className="nav-item">
                                                        <a
                                                            className={
                                                                "nav-link " + (currentPage === "template" && "active")
                                                            }
                                                            aria-current="page"
                                                            onClick={() => updateCurrentTab('template')}
                                                            href="#"
                                                        >Template</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className={
                                                                "nav-link " + (currentPage === "website" && "active")
                                                            }
                                                            aria-current="page"
                                                            onClick={() => updateCurrentTab('website')}
                                                            href="#"
                                                        >
                                                            Website Details
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className={
                                                                "nav-link " + (currentPage === "agent" && "active")
                                                            }
                                                            onClick={() => updateCurrentTab("agent")}
                                                            href="#"
                                                        >
                                                            Agent
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className={
                                                                "nav-link " + (currentPage === "brokerage" && "active")
                                                            }
                                                            onClick={() => updateCurrentTab("brokerage")}
                                                            href="#"
                                                        >
                                                            Brokerage
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className={
                                                                "nav-link " + (currentPage === "plan" && "active")
                                                            }
                                                            onClick={() => updateCurrentTab("plan")}
                                                            href="#"
                                                        >
                                                            Plan
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className={
                                                                "nav-link " + (currentPage === "payment" && "active")
                                                            }
                                                            onClick={() => updateCurrentTab("payment")}
                                                            href="#"
                                                        >
                                                            Payment
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-12  col-md-8 col-sm-10 mx-auto px-3 mb-5 my-lg-0">
                                                {stripeSecret ? (
                                                    <Elements
                                                        stripe={stripePromise}
                                                        options={{clientSecret: stripeSecret}}
                                                    >
                                                        <h5>Let’s create your account</h5>
                                                        <p>
                                                            Please tell a bit about you so that we can personalize
                                                            your on boarding experience.
                                                        </p>
                                                        {steps
                                                            .filter((d) => d.type === currentPage)
                                                            .map(({Component}, i) => (
                                                                <Component store={AccountCreationStore} nextStep={nextStep}
                                                                           ref={tabRef} setSuccess={setSuccess}
                                                                           key={'route-' + i}/>
                                                            ))}
                                                    </Elements>
                                                ) : (
                                                    <Loading/>
                                                )}
                                                {(currentPage !== "payment" && currentPage !== "plan") && (
                                                    <Button
                                                        title={"Next"}
                                                        type="solid"
                                                        className="mt-3 btn-block w-100"
                                                        onClick={nextStep}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccountCreationProvider>
                        :
                        <AccountSuccess/>
                }
            </>
        );
    })
);
export default AccountCreation;
