import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {UserStore} from "../../stores/user.store";
import {accessTokenLogin, checkDNS} from "../Authentication/auth.api";
import {Loading} from "../../common/components/Loading";
import {DashboardSidebar} from "./DashboardSidebar";
import {ToasterStore} from "../../stores/toaster.store";

export const DashboardWrapper = () => {
    const [loading, setLoading] = useState(true);
    useEffect(async function () {
        try {
            await accessTokenLogin();
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }, []);

    async function checkDNSSTATUS() {
        setLoading(true);
        const response = await checkDNS({
            website_id: UserStore.user.website.website_id
        });
        if (!response.api_status) {
            ToasterStore.setSuccessMessage('DNS updated successfully.');
            UserStore.updateUserFromServer();
        } else {
            ToasterStore.setErrorMessage(response.message);
        }
        setLoading(false);
    }

    return <div className="container py-5">
        {
            !loading ?
                <div className="row">
                    <div className="col-12 mb-3 mb-lg-0 col-lg-2">
                        <DashboardSidebar/>
                    </div>
                    <div className="col-12 col-lg-10">
                        <div className="border rounded p-3">
                            <Outlet context={[UserStore, checkDNSSTATUS]}/>
                        </div>
                    </div>
                </div>
                :
                <Loading/>
        }
    </div>
};
