import React, {useEffect, useState} from "react";
import {getPolicy} from "../Authentication/auth.api";
import {useParams} from "react-router-dom";

export const Policies = (match) => {
    let {policyType} = useParams();
    const [policy, setPolicy] = useState({title: '', content: ''});

    useEffect(async function () {
        const policyDocument = await getPolicy({policy_name: policyType});
        if (policyDocument?.title) {
            setPolicy(policyDocument)
        }
    }, [policyType]);
    return <>
        <div className="py-my">
            <div className="container-fluid  mt-5">
                <div className="container">
                    <div className="text-center">
                        <h3><span>{policy.title}</span></h3>
                        <p>We are here to help you with all your queries and concerns</p>
                    </div>
                    <p dangerouslySetInnerHTML={{__html: policy.content}}/>

                </div>
            </div>
        </div>
    </>
};
