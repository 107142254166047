import { useState, useEffect } from "react";
import { getAPI } from "../pages/Authentication/auth.api";

export const useDetailsData = (url) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    try {
      const fetchData = async () => {
        setLoading(true);
        const result = await getAPI(`${url}`);
        setLoading(false);
        setData(result);
      };
      fetchData();
    } catch (e) {
      setError(e.message);
    }
  }, [url]);

  return {
    data,
    loading,
    error,
  };
};
