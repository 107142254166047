import {UserStore} from "../../stores/user.store";
import {ToasterStore} from "../../stores/toaster.store";

function getHeaders() {
    let token = localStorage.getItem('access_token');
    return {
        authorization: 'Bearer ' + token
    }
}

export async function registerUser(user) {
    return await fetch(process.env.REACT_APP_API_URL + '/auth/registerUser', {
        method: 'post',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(d => d.json()).then(d => {
            if (!d.api_status) {
                localStorage.setItem('access_token', d.access_token);
            }
            return d;
        }).then(d => {
            if (!d.api_status) {
                return accessTokenLogin(d)
            } else {
                return d;
            }
        })
        .catch(d => ({message: 'Something went wrong.'}));
}

export async function loginUser(user) {
    return await fetch(process.env.REACT_APP_API_URL + '/auth', {
        method: 'post',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(d => d.json()).then(d => {
        if (!d.api_status) {
            UserStore.clearOtherData();
            localStorage.setItem('access_token', d.access_token);
            if (d.isReset) {
                ToasterStore.setSuccessMessage('Please reset password.')
            }
        }
        return d;
    }).then(d => {
        if (!d.api_status) {
            return accessTokenLogin(d)
        } else {
            return d;
        }
    }).catch(d => ({message: 'Something went wrong.', api_status: 400}));
}

export async function accessTokenLogin(d) {
    return await fetch(process.env.REACT_APP_API_URL + '/user/accessTokenLoginWeb', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json()).then(response => {
        if (!response.api_status) {
            UserStore.setUser(response);
            return {...d, ...response}
        }
        UserStore.logout();
        window.location.reload();
        return d;
    }).catch(d => {
        UserStore.logout();
        window.location.reload();
        return d;
    });
}

export async function createStripeIntent(d) {
    return await fetch(process.env.REACT_APP_API_URL + 'payment/createIntent', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}


export async function createCharge(data) {
    return await fetch(process.env.REACT_APP_API_URL + 'payment/chargeAndWebsite', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}

export async function savePaymentMethod(data) {
    return await fetch(process.env.REACT_APP_API_URL + 'payment/paymentMethods', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}


export async function forgotPassword(data) {
    return await fetch(process.env.REACT_APP_API_URL + 'auth/forgotPassword', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}


export async function resetPassword(data) {
    return await fetch(process.env.REACT_APP_API_URL + 'user/resetPassword', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}


export async function createWebsite(data) {
    return await fetch(process.env.REACT_APP_API_URL + 'user/addWebsite', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}


export async function verifyToken(code) {
    return await fetch(process.env.REACT_APP_API_URL + 'auth/verifyUserToken?code=' + code, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json()).catch(e => {
        return false;
    });
}


export async function setData() {
    return await fetch(process.env.REACT_APP_API_URL + 'general/siteData', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(d => d.json());
}



export async function contactUs(data) {
    return await fetch(process.env.REACT_APP_API_URL + 'general/contactUs', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(d => d.json());
}


export async function checkDNS(data) {
    return await fetch(process.env.REACT_APP_API_URL + 'user/checkDNS', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}

export async function setCardPrimary(data) {
    return await fetch(process.env.REACT_APP_API_URL + 'payment/setPrimaryCard', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}

export async function applyPromoCode(data) {
    return await fetch(process.env.REACT_APP_API_URL + 'payment/applyPromoCode', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}
export async function checkDomainExists(base_url) {
    return await fetch(process.env.REACT_APP_API_URL + 'user/checkDomainExists', {
        method: 'post',
        body: JSON.stringify({base_url}),
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}

export async function removeCardData({upm_id}) {
    return await fetch(process.env.REACT_APP_API_URL + 'payment/paymentMethods?upm_id=' + upm_id, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json());
}

export async function getPaymentMethods() {
    return await fetch(process.env.REACT_APP_API_URL + 'payment/paymentMethods', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json())
        .then(response => {
            if (!response.api_status) {
                return response;
            }
            return [];
        })
        .catch(e => {
            ToasterStore.setErrorMessage(e.message);
            return [];
        });
}
export async function getTransactions() {
    return await fetch(process.env.REACT_APP_API_URL + 'payment/transactions', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        }
    }).then(d => d.json())
        .then(response => {
            if (!response.api_status) {
                return response;
            }
            return [];
        })
        .catch(e => {
            ToasterStore.setErrorMessage(e.message);
            return [];
        });
}
export async function getOverdue() {
    return await fetch(process.env.REACT_APP_API_URL + 'payment/getOverdue', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        },
        body: JSON.stringify({website_id: UserStore.user.website.website_id})
    }).then(d => d.json())
        .then(response => {
            if (!response.api_status) {
                return response;
            }
            return {};
        })
        .catch(e => {
            ToasterStore.setErrorMessage(e.message);
            return {};
        });
}

export async function deactivatePlan() {
    return await fetch(process.env.REACT_APP_API_URL + 'website/togglePlan', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders(),
            'x-website-id': UserStore.user.website.website_id
        }
    }).then(d => d.json())
        .then(response => {
            if (!response.api_status) {
                return response;
            }
            return {};
        })
        .catch(e => {
            ToasterStore.setErrorMessage(e.message);
            return {};
        });
}

export async function payOverduePayment() {
    return await fetch(process.env.REACT_APP_API_URL + 'payment/payOverduePayment', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            ...getHeaders()
        },
        body: JSON.stringify({website_id: UserStore.user.website.website_id})
    }).then(d => d.json())
        .then(response => {
            if (!response.api_status) {
                return response;
            }
            return false;
        })
        .catch(e => {
            ToasterStore.setErrorMessage(e.message);
            return false;
        });
}

export async function getPolicy({policy_name}) {
    return await fetch(process.env.REACT_APP_API_URL + 'general/policy?policy_name=' + policy_name, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(d => d.json());
}

export async function getBlogList({page}) {
    const limit = 10;
    const offset = (page-1) * limit;
    return await fetch(process.env.REACT_APP_API_URL + 'general/blog?offset=' + offset + '&limit=' + limit,  {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(d => d.json())
    .then(response => {
        if (!response.api_status) {
            return {blog: response.data, total_count: 0};
        }
        return {blog: [], total_count: 0};
    })
    .catch(e => {
        ToasterStore.setErrorMessage(e.message);
        return {blog: [], total_count: 0};
    });
}


export async function getAPI(url) {
    return await fetch(process.env.REACT_APP_API_URL + url,  {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(d => d.json())
}