import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Navigate} from "react-router-dom";
import {ToasterStore} from "../../../stores/toaster.store";
import {Loading} from "../Loading";

export const PrivateRoutesContainer = observer(({
                                                    children, navStore, routeTree, match, path, userStore, isPrivate,
                                                    isAuthenticationTrueRedirect,
                                                    isAuthenticationRoute, loggedInDisallowed
                                                }) => {
    useEffect(function () {
        updateRouteTree();
    });
    const updateRouteTree = () => {
        navStore.updateRouteTree(routeTree);
        navStore.updateMatch(match);
        navStore.updateBasePath(path);
    };
    const notLoggedIn = () => {
        ToasterStore.setErrorMessage('User is not logged in.');
        return <Navigate to={isAuthenticationTrueRedirect}/>
    };
    if (loggedInDisallowed && userStore.isLoggedIn && userStore.user && userStore.user.website ) {
        if (userStore.user.website.plan) {
            return <Navigate to="/dashboard/home"/>
        } else {
            return <Navigate to="/auth/account-setup"/>
        }
    }
    /*
        if (userStore.isLoggedIn && isAuthenticationRoute) {
            return <Navigate to={isAuthenticationTrueRedirect}/>
        }*/
    if (userStore.loginChecking) {
        return <Loading/>;
    }
    return <React.Fragment>
        {
            isPrivate ? userStore.isLoggedIn ? children : notLoggedIn() : children
        }

    </React.Fragment>;
});
