import Button from "../../../common/components/button";
import React, {createRef, useEffect} from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import {UserStore} from "../../../stores/user.store";
import {useNavigate} from 'react-router-dom';
import {loginUser} from "../auth.api";
import {ToasterStore} from "../../../stores/toaster.store";
import ReCAPTCHA from "react-google-recaptcha";

const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password should be at least 6 chars').required('Password is required')
});

export const SignIn = () => {
    const _reCaptchaRef = createRef();
    const history = useNavigate();
    const loginValue = {
        email: '',
        password: '',
        recaptcha: Yup.string().required(),
    };
    useEffect(function () {
        _reCaptchaRef.current.execute();
    }, []);

    async function submitLogin(values, {setSubmitting}) {
        _reCaptchaRef.current.execute();
        const response = await loginUser(values);
        if (!response.api_status) {
            if (response.isReset) {
                history('/dashboard/reset-password')
            } else if (response.website && response.website.base_url) {
                history('/dashboard/home')
            } else {
                history('/auth/account-setup')
            }
            UserStore.setLogin(true);
        } else {
            ToasterStore.setErrorMessage(response.message);
        }
        setSubmitting(false);
    }

    return (
        <div className="py-my">
            <div className="container-fluid  ">
                <div className="container">
                    <div className="row px-0 py-3 p-lg-5">

                        <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-md-6 mx-auto border rounded p-5">
                            <h4 className="text-primary underline text-center">Sign in</h4>
                            <Formik
                                initialValues={loginValue}
                                validationSchema={loginSchema}
                                onSubmit={submitLogin}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                      setFieldValue,
                                      setSubmitting
                                  }) => (
                                    <form onSubmit={handleSubmit} >
                                        <div className="form-group mt-2">
                                            <label htmlFor="" className="form-label "> Email</label>
                                            <input className="form-control " placeholder="Email" name="email"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.email}/>
                                            <p className="small text-danger pt-1">{errors.email && touched.email && errors.email}</p>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="" className="form-label "> Password</label>
                                            <input className="form-control " type="password" name="password"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.password}/>
                                            <p className="small text-danger pt-1">{errors.password && touched.password && errors.password}</p>

                                        </div>
                                        <ReCAPTCHA
                                            ref={_reCaptchaRef}
                                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                            onChange={(value) => {
                                                setFieldValue("recaptcha", value);
                                                setSubmitting(false);
                                            }}
                                            size="invisible"
                                        />
                                        <Button target="_self" href="/auth/forgotPassword" title="Forgot Password?"
                                                isLink={true}/>
                                        <Button title={"Login"} type="solid"
                                                isSubmit={true}
                                                className="mt-3 btn-block w-100" disabled={isSubmitting}/>
                                        <Button title={"Register"} type="outline" href={'/auth/register'}
                                                className="mt-3 btn-block w-100"/>
                                    </form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
