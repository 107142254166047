import React from "react";
import Step1 from '../../assets/img/agent-agreement/step-1.jpg'
import Step2 from '../../assets/img/agent-agreement/step-2.jpg'
import Step3 from '../../assets/img/agent-agreement/step-3.jpg'
import Step4 from '../../assets/img/agent-agreement/step-4.jpg'
import Step5 from '../../assets/img/agent-agreement/step-5.jpg'
import Step6 from '../../assets/img/agent-agreement/step-6.jpg'
import Step7 from '../../assets/img/agent-agreement/step-7.jpg'
import Step8 from '../../assets/img/agent-agreement/step-8.jpg'
import Step9 from '../../assets/img/agent-agreement/step-9.jpg'
import Step10 from '../../assets/img/agent-agreement/step-10.jpg'
import Step11 from '../../assets/img/agent-agreement/step-11.jpg'
import Step12 from '../../assets/img/agent-agreement/step-12.jpg'
import './index.scss';
export const AgentAgreement = () => {
    return <>
        <div className="agreement py-my">
            <div className="container-fluid  mt-5">
                <div className="container">
                    <div className="text-center">
                        <h3>TRREB Data Agreements</h3>

                    </div>
                    <p>
                        If you are a TRREB member and would like to fill out the online IDX/VOW forms,
                        please follow these instructions on how to complete these below:
                        <br/>
                        1. Data License Agreement <br/>
                        2. IDX Data Agreement <br/>
                        3. VOW Data Agreement <br/>
                    </p>

                    <ol>
                        <li> Visit <a
                            href="https://profile.torontomls.net/oas/common/login.jsp">https://profile.torontomls.net/oas/common/login.jsp</a>
                            to log in with your User ID, PIN #, and token/authenticator password
                            <br/>
                            <img className="img img-fluid rounded" src={Step1} alt="step 1"/>
                        </li>
                        <li>
                            Step 2: When logged in, click on the Start New tab. You will need to repeat this step for each agreement.
                        </li>
                        <li>
                            After you have clicked Start New, you will select the Data License Agreement and then click on the Continue button.
                            <br/>
                            <img className="img img-fluid rounded" src={Step2} alt="step 2"/>
                            <br/>
                            <p>Next, you will have to check all select buttons. Please note to verify your contact information and brokerage information is correct.</p>
                            <img className="img img-fluid rounded" src={Step3} alt="step 3"/>
                            <br/>
                            <p>Next: For the Third-Party provider please select <b>AgentEdge Solutions</b></p>
                            <p>For Third-Party Contact enter in <b>Support</b> and for Third-Party Email enter <b>info@agenedge.ca</b></p>
                            <img className="img img-fluid rounded" src={Step4} alt="step 4"/>
                        </li>
                        <li>
                            IDX Data agreement
                            <br/>
                            <img className="img img-fluid rounded" src={Step5} alt="step 5"/>
                            <br/>
                            <img className="img img-fluid rounded" src={Step6} alt="step 6"/>
                            <br/>
                            <img className="img img-fluid rounded" src={Step7} alt="step 7"/>
                            <br/>
                            <img className="img img-fluid rounded" src={Step8} alt="step 8"/>
                        </li>
                        <li>
                            VOW data agreement
                            <br/>
                            <img className="img img-fluid rounded" src={Step9} alt="step 9"/>
                            <br/>
                            <img className="img img-fluid rounded" src={Step10} alt="step 10"/>
                            <br/>
                            <img className="img img-fluid rounded" src={Step11} alt="step 11"/>
                            <br/>
                            <img className="img img-fluid rounded" src={Step12} alt="step 12"/>
                        </li>
                    </ol>
                </div>
            </div>
        </div>

    </>
};
