import React, {useState} from "react";
import {UserStore} from "../../../stores/user.store";
import {observer} from "mobx-react";
import useCopyToClipboard from "../../../utils/useCopyToClipboard";
import * as moment from 'moment';
import {Clipboard} from 'react-bootstrap-icons'

export const ReferAndEarn = observer(() => {
    const [value, copy] = useCopyToClipboard();
    const [showCopy, setShowCopy] = useState(false);

    return <>
        <div>
            <h4 className="text-center text-primary underline">Refer & Earn</h4>
            <div className="profile-details py-5">
                <div className="row pb-3 mb-md-0">
                    {/*<h5 className="text-secondary">Profile</h5>*/}
                    <div className="col-12 border-bottom pb-3  ">
                        <div className=" d-flex flex-column  gap-2">
                            <h5 className="text-muted">Your unique referral Code</h5>
                            {/*<div className={'d-flex gap-2 align-items-center'}>*/}
                            {/*    <h3 className=" pointer bg-success p-2 rounded text-light ">{UserStore.user.referral_code}</h3>*/}
                            {/*</div>*/}
                            <div className={''}>
                                <p className=""><strong>{UserStore.user.referral_code}</strong></p>
                                <p>OR</p>
                                <p className="bg-light p-3 text-dark d-flex justify-content-between align-items-center"
                                   onMouseEnter={() => setShowCopy(true)}
                                   onMouseLeave={() => setShowCopy(false)}
                                   onClick={() => copy(`https://agentedge.ca/auth/register?referral_code=${UserStore.user.referral_code}`)}
                                   style={{cursor: 'pointer'}}>https://agentedge.ca/auth/register?referral_code={UserStore.user.referral_code}
                                    {
                                        showCopy && <Clipboard color={"success"}/>
                                    }

                                </p>
                            </div>
                            <p className="py-0 my-0 text-success">You are
                                getting <b>{UserStore.user.totalDiscount}</b> discount.</p>
                            <p className="py-0 my-0"><small>You will get discount in next payment cycle for the websites
                                which completed their trial.</small></p>

                        </div>
                    </div>
                </div>
                <div className="row pb-3 mb-md-0">
                    <div className="col-12">
                        <h5 className="text-secondary">You AgentEdge Referrals</h5>
                        {
                            UserStore.user.referralWebsites.length === 0 ? 'No data available' :
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <td>Website</td>
                                        <td>Trial</td>
                                        <td>Status</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        UserStore.user.referralWebsites.map((website, key) => <tr key={key}>
                                            <td>{website.base_url}</td>

                                            <td>{
                                                website.isTrial ? 'Yes' : 'No'
                                            }</td>
                                            <td>
                                                {moment(website.plan_end_date).isAfter(moment()) ?
                                                    <span className="text-light p-1 rounded bg-success">Active</span> :
                                                    <span className="text-light p-1 rounded bg-danger">InActive</span>}
                                            </td>
                                        </tr>)
                                    }
                                    </tbody>
                                </table>
                        }
                    </div>
                </div>


            </div>
        </div>
    </>
});
