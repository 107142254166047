import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AccountCreationStore } from "../../stores/accountCreation.store";
import { Loading } from "../../common/components/Loading";
import { observer } from "mobx-react";
import "./blog.scss";
import { getBlogList } from "../Authentication/auth.api";
import { useFetchData } from "../../utils/listApi";
import Moment from "react-moment";
export const Blog = observer(() => {
  const navigate = useNavigate();
  const { data, loadPrev, loading, loadNext } = useFetchData("/general/blog");

  return loading ? (
    <>
      <Loading />
    </>
  ) : (
    <div className="py-my blog-list">
      <div className="container-fluid  mt-5">
        <div className="container">
          <div className="text-center">
            <h3 className="underline">
              <span>Insights & Inspiration</span>
            </h3>
            <p>The Latest From Our Blog</p>
          </div>
          <div className="row ">
            <div class="container px-4 px-lg-5">
              <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-md-10 col-lg-8 col-xl-7">
                  {data.map((blog) => (
                    <>
                      <div className="d-flex flex-column flex-lg-row justify-content-between gap-0 gap-lg-3">
                        <div className="post-preview order-lg-1 order-2">
                          <NavLink to={"/blog/" + blog.post_id}>
                            <h4 className="post-title">{blog.post_title}</h4>
                            <h4 className="post-subtitle">
                              {blog.post_excerpt}
                            </h4>
                          </NavLink>
                          <p className="post-meta">
                            Posted on{" "}
                            <Moment format="MMM DD YYYY">{blog.created}</Moment>
                          </p>
                        </div>
                        {blog.post_main_image && (
                          <img
                            
                            src={blog.post_main_image}
                            className="img img-fluid order-1 order-lg-2"
                            alt="blogImage"
                          />
                        )}
                      </div>

                      <hr className="my-4" />
                    </>
                  ))}

                  <div
                    class={
                      "d-flex  mb-4 " +
                      (loadPrev
                        ? "justify-content-between"
                        : "justify-content-end")
                    }
                  >
                    {loadPrev && (
                      <a
                        class="btn btn-primary text-uppercase"
                        onClick={loadPrev}
                      >
                        ← New Posts
                      </a>
                    )}
                    {loadNext && (
                      <a
                        class="btn btn-primary text-uppercase"
                        onClick={loadNext}
                      >
                        Older Posts →
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
