import {observer} from "mobx-react";
import {Outlet} from "react-router-dom";
import React from "react";
import {UserStore} from "../../../stores/user.store";

export const AuthContainer = observer(({children}) => {
    return <>
        <Outlet/>
    </>
});
