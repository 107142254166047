import React from "react";
import NavLink from "../../common/components/NavLink";
import {AccountCreationStore} from "../../stores/accountCreation.store";

export const HowToSetupList = () => {
    return <>
        <div className="py-my">
            <div className="container">
                <div className="text-center">
                    <h3 className="underline"><span>Setup Guide</span></h3>
                    <p>Here are the list of basic setup guide for your AgentEdge Account.</p>
                </div>
                <div className="list-group mt-5">
                    {
                        AccountCreationStore.howToGuide.map((guide, key) => <div className="list-group-item border-0"
                                                                                 key={key}>
                            <div className="row ">
                                <div className="col-4"><img src={guide.img} alt=""
                                                            className="img img-fluid img-thumbnail p-0"/></div>
                                <div className="col-8">
                                    <h5 className="text-primary">
                                        <NavLink className="text-decoration-none"
                                                 to={guide.path}>{guide.title}</NavLink>
                                    </h5>
                                    <p className="text-muted">{guide.description}</p>
                                    <p className="small text-end"><i>Last modified: {guide.modified}</i></p>
                                </div>
                            </div>
                        </div>)
                    }

                </div>
            </div>
        </div>
    </>
};
