import React from "react";

export const FAQ = ({list}) => {
    return <>
       {list?.length > 0 && <div className="container mt-5  mb-3 ">
           <h3>Frequently Asked Questions</h3>

           <div className="accordion" id="accordionPanelsStayOpenExample">
               {
                   list.map((item, key) => <div className="accordion-item" key={key}>
                       <h2 className="accordion-header" id={"panelsStayOpen-heading" + key}>
                           <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                   data-bs-target={"#panelsStayOpen-collapse" + key} aria-expanded="true"
                                   aria-controls={"panelsStayOpen-collapse" + key}>
                               {item.faq_title}
                           </button>
                       </h2>
                       <div id={"panelsStayOpen-collapse" + key} className="accordion-collapse collapse show"
                            aria-labelledby={"panelsStayOpen-heading" + key}>
                           <div className="accordion-body">{item.faq_description}</div>
                       </div>
                   </div>)
               }
           </div>
       </div>}

    </>
};
