import React, {useEffect, useState} from "react";
import './header.scss';
import Logo from '../../../assets/img/logo.png';
import Button from "../button";
import NavLink from "../NavLink";
// import {UserProfileIcon} from "../../../utils/images-export";
import {ToasterMessage} from "../Toaster";
import {UserStore} from "../../../stores/user.store";
import {Container, Dropdown, Navbar} from "react-bootstrap";
import {useLocation, useNavigate} from 'react-router-dom';
import {ToasterStore} from "../../../stores/toaster.store";
import {observer} from "mobx-react";
import nav from '../../../utils/nav';
import {PersonCircle} from 'react-bootstrap-icons';

const Header = observer((props) => {
    const history = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const {pathname} = useLocation();

    useEffect(() => {
        setOpen(false); // Close the navigation panel
    }, [ pathname ]);

    function logoutUser() {
        UserStore.logout();
        ToasterStore.setSuccessMessage('User logout successfully');
        history('/')
    }
    
    return <header className=" ">
        <ToasterMessage/>

        <div className="header-info">
            <a href="/auth/register">Register today</a>  and get Early birds <span>discount</span> , Get upto <span>20% off</span> on annual
            subscription
        </div>
        <Navbar bg="white" expand="lg" collapseOnSelect expanded={isOpen} onToggle={() => setOpen(!isOpen)}>
            <Container>
                <Navbar.Brand >
                    <NavLink className="navbar-brand" to="/">
                        <img src={Logo} alt=""/>
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"  />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end gap-3">
                    <ul className="navbar-nav gap-0 gap-lg-4">
                        {
                            nav.map((item, key) => <li className="nav-item" key={key}>
                                    <NavLink to={item.path} className="nav-link ">
                                        {item.name}
                                    </NavLink>
                                </li>
                            )
                        }

                    </ul>
                    <div className="d-flex gap-3">
                        <Button title={"Contact Now"} href={"contact"} type="outline"/>

                        <Dropdown  align={{ lg: 'end' }} >
                            <Dropdown.Toggle variant="outline-success" size="sm">
                                <PersonCircle size={"27px"}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu  >
                                {
                                    UserStore.isLoggedIn ?
                                        <>
                                            {
                                                UserStore.user.website?.plan_end_date ?
                                                    <Dropdown.Item
                                                        children={<Button title="Dashboard" href={"/dashboard/home"}
                                                                          isLink={true}/>}/>
                                                    :
                                                    <>
                                                        <Dropdown.Item children={<Button title="Continue Registration"
                                                                                         href={"/auth/account-setup"}
                                                                                         isLink={true}/>}/>

                                                    </>
                                            }
                                            <Dropdown.Item>
                                                <Button title="Logout" onClick={logoutUser} isLink={true}/>
                                            </Dropdown.Item>
                                        </>
                                        :
                                        <>

                                            <Dropdown.Item>
                                                <Button title="Login" href={"/auth/login"} isLink={true}/>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <Button title="Register" href={"/auth/register"} isLink={true}/>
                                            </Dropdown.Item>
                                        </>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header>
});

export default Header;
