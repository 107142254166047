import {AccountCreationStore} from "../../stores/accountCreation.store";
import NavLink from "../../common/components/NavLink";
import React from "react";
import {Outlet} from 'react-router-dom';

export const HowToContainer = () => {
    return <>
        <div className="py-my">
            <div className="container my-5">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <Outlet/>
                    </div>
                    <div className="col-12 col-md-4">
                        <h5 className="underline text-center text-primary">How to setup</h5>
                        <div className="list-group mt-5">
                            {
                                AccountCreationStore.howToGuide.map((guide, key) => <div
                                    className="list-group-item border-0"
                                    key={key}>
                                    <div className="row ">
                                        <div className="col-4"><img src={guide.img} alt=""
                                                                    className="img img-fluid img-thumbnail p-0"/></div>
                                        <div className="col-8">
                                            <h5 className="text-primary">
                                                <NavLink className="text-decoration-none"
                                                         to={guide.path}>{guide.title}</NavLink>
                                            </h5>
                                            <p className="small text-end"><i>Last modified: {guide.modified}</i></p>
                                        </div>
                                    </div>
                                </div>)
                            }

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
};
