import Button from "../../../common/components/button";
import React, {createRef, useEffect, useState} from "react";
import {Formik} from "formik";
import * as Yup from 'yup';
import {ToasterStore} from "../../../stores/toaster.store";
import {registerUser} from "../auth.api";
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SignupSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Name should be at least 3 chars.')
        .max(50, 'Name should be at max 50 chars.')
        .required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password should be at least 6 chars').required('Password is required'),
    confirmPassword: Yup.string().test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value
    }),
    phone: Yup.number()
        .required('Phone number is required')
        .test('len', 'Phone number needs to be exactly 10 digits', val => val && val.toString().length === 10)
});
export const Register = () => {
    const _reCaptchaRef = createRef();
    const history = useNavigate();
    const query = useQuery();
    const [referral_code, setReferralCode] = useState(null);
    useEffect(function () {
        _reCaptchaRef.current.execute();
        if (query.get('referral_code')) {
            localStorage.setItem('referral_code', query.get('referral_code'));
            setReferralCode(query.get('referral_code'));
        } else if (localStorage.getItem('referral_code')) {
            setReferralCode(localStorage.getItem('referral_code'));
        }
    }, []);


    const registerValues = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
        recapcha: '',
        referral_code
    };

    async function submitToRegister(values, {setSubmitting, ...args}) {
        const response = await registerUser({referral_code, ...values});
        if (!response.api_status) {
            ToasterStore.setSuccessMessage('Registration done successfully.');
            /*once registration is done remove referral code*/
            localStorage.removeItem('referral_code');
            history('/auth/account-setup');

        } else {
            ToasterStore.setErrorMessage(response.message)
        }
        _reCaptchaRef.current.execute();
        setSubmitting(false);
    }


    return (
        <div className="py-my">
            <div className="container-fluid  ">
                <div className="container">
                    <div className="row  p-5">
                        <div className="col-12 col-lg-6 mx-auto border rounded p-5">
                            <h4 className="text-primary underline text-center">Register</h4>
                            <Formik
                                initialValues={registerValues}
                                validationSchema={SignupSchema}
                                enableReinitialize={true}
                                onSubmit={submitToRegister}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                      setFieldValue,
                                      setSubmitting
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group ">
                                            <label htmlFor="" className="form-label "> Full Name</label>
                                            <input className="form-control " placeholder="" name="name"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   required

                                                   value={values.name}/>
                                            <p className="small text-danger pt-1">{errors.name && touched.name && errors.name}</p>

                                        </div>
                                        <div className="form-group ">
                                            <label htmlFor="" className="form-label "> Email</label>
                                            <input className="form-control " placeholder="" name="email"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   required

                                                   value={values.email}/>
                                            <p className="small text-danger pt-1">{errors.email && touched.email && errors.email}</p>

                                        </div>
                                        <div className="form-group ">
                                            <label htmlFor="" className="form-label "> Phone</label>
                                            <input className="form-control " placeholder="" name="phone"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   required
                                                   value={values.phone}
                                            />
                                            <p className="small text-danger pt-1">{errors.phone && touched.phone && errors.phone}</p>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="" className="form-label "> Password</label>
                                            <input className="form-control" placeholder="" type="password"
                                                   name="password"
                                                   onChange={handleChange}
                                                   required
                                                   onBlur={handleBlur}
                                                   value={values.password}
                                            />
                                            <p className="small text-danger pt-1">{errors.password && touched.password && errors.password}</p>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="" className="form-label "> Confirm Password</label>
                                            <input className="form-control " type="password" name="confirmPassword"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.confirmPassword}/>
                                            <p className="small text-danger pt-1">{errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}</p>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="" className="form-label ">Referral Code</label>
                                            <input className="form-control " placeholder="" name="referral_code"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.referral_code}/>
                                        </div>
                                        <ReCAPTCHA
                                            ref={_reCaptchaRef}
                                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                            onChange={(value) => {
                                                setFieldValue("recaptcha", value);
                                                setSubmitting(false);
                                            }}
                                            size="invisible"
                                        />
                                        <p className="text-muted mb-0 pb-0"><i><small>By clicking <b>register</b> button
                                            you are accepting are <NavLink
                                                to={'/policies/terms-conditions'}>terms and conditions</NavLink>.
                                        </small></i></p>
                                        <Button title={"Register"} type="solid"
                                                isSubmit={true}
                                                className="mt-3 btn-block w-100" disabled={isSubmitting}/>
                                        <Button title={"Login"} type="outline" href={'/auth/login'}
                                                className="mt-3 btn-block w-100"/>
                                    </form>
                                )}
                            </Formik>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
