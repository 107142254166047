import {UserProfile} from "./Profile";
import {ResetPassword} from "./ResetPassword";
import {HowToSetupList} from "../HowToSetup";
import {UserPaymentMethods} from "./PaymentMethods";
import {Billing} from "./Billing";
import {ReferAndEarn} from "./Referral";

export const dashboardRoutes = [
    {
        path: '/dashboard/home',
        Component: UserProfile,
        isPrivate: true,
        isAuthenticationRoute: false,
        isAuthenticationTrueRedirect: '/auth/login',
        seo: {
            title: 'User Profile'
        }
    },
    {
        path: '/dashboard/reset-password',
        Component: ResetPassword,
        isPrivate: true,
        isAuthenticationRoute: false,
        isAuthenticationTrueRedirect: '/auth/login',
        seo: {
            title: 'User Profile'
        }
    },
    {
        path: '/dashboard/payment-methods',
        Component: UserPaymentMethods,
        isPrivate: true,
        isAuthenticationRoute: false,
        isAuthenticationTrueRedirect: '/auth/login',
        seo: {
            title: 'User Payment Methods'
        }
    },
    {
        path: '/dashboard/transactions',
        Component: Billing,
        isPrivate: true,
        isAuthenticationRoute: false,
        isAuthenticationTrueRedirect: '/auth/login',
        seo: {
            title: 'Billing'
        }
    },
    {
        path: '/dashboard/how-to-setup',
        Component: HowToSetupList,
        isPrivate: true,
        isAuthenticationRoute: false,
        isAuthenticationTrueRedirect: '/auth/login',
        seo: {
            title: 'User Profile'
        }
    },
    {
        path: 'refer-earn',
        Component: ReferAndEarn,
        isPrivate: true,
        isAuthenticationRoute: false,
        isAuthenticationTrueRedirect: '/auth/login',
        seo: {
            title: 'Refer & Earn'
        }
    },
];
