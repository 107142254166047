import React, {useState} from "react";
import {UserStore} from "../../../stores/user.store";
import Moment from "react-moment";
import Button from "../../../common/components/button";
import {observer} from "mobx-react";
import {ToasterStore} from "../../../stores/toaster.store";
import {deactivatePlan} from "../../Authentication/auth.api";
import {EnvelopeFill, PersonCircle, PhoneFill} from 'react-bootstrap-icons';
import * as moment from "moment";
import {NavLink} from "react-router-dom";

export const UserProfile = observer(() => {
    const [deactivating, setDeactivating] = useState(false);


    async function onDeactivate() {

        setDeactivating(true);
        const response = await deactivatePlan();
        setDeactivating(false);
        document.querySelector('.paymentModalClose').click();
        await UserStore.updateUserFromServer();
        if (response) {
            ToasterStore.successMessage('Plan deactivated successfully.');
        }
    }

    function isPlanEnded() {
        return moment().isAfter(UserStore.user.website.plan_end_date);
    }

    return <>
        <div>
            <h4 className="text-center text-primary underline">Dashboard</h4>
            <div className="profile-details py-5">
                <div className="row pb-3 mb-md-0">
                    {/*<h5 className="text-secondary">Profile</h5>*/}
                    <div className="col-12 border-bottom pb-3  ">
                        <div className=" d-flex flex-column text-muted gap-2">
                            <div className="text-break d-flex gap-2 align-items-center ">
                                <PersonCircle/>
                                {UserStore.user.name}</div>
                            <div className="text-break d-flex gap-2 align-items-center">
                                <EnvelopeFill/>
                                {UserStore.user.email}</div>
                            <div className="text-break d-flex gap-2 align-items-center">
                                <PhoneFill/>
                                {UserStore.user.contact || '-'}</div>
                        </div>
                    </div>
                </div>
                <div className="row pb-3 mb-md-0">
                    <h5 className="text-secondary">Website Details</h5>
                    <div className="col-6 col-md-3 ">
                        <h6 className="text-muted">Domain</h6>
                        <p className="text-break text-black">{UserStore.user.website.base_url || '-'}</p>
                    </div>
                    <div className="col-6 col-md-3 ">
                        <h6 className="text-muted">AgentEdge URL</h6>
                        <p className="text-break text-black">
                            <a href={"https://" + UserStore.user.website.subdomain + ".agentedge.ca"} target="_blank">
                                https://{UserStore.user.website.subdomain}.agentedge.ca
                            </a>
                        </p>
                    </div>
                    <div className="col-6 col-md-3 ">
                        <h6 className="text-muted">DNS</h6>
                        <p className="text-break text-black">
                            {UserStore.user.website.cloudflare_dns? <>
                                {UserStore.user.website.cloudflare_dns.split(',').join('\n')}
                                <br/>
                                <Button isLink={true} to="/how-to-setup/name-server" title="How to Setup?" className="small"/>

                            </>  : '-'}
                        </p>
                    </div>
                    <div className="col-6 col-md-3 ">
                        <h6 className="text-muted">Domain Status</h6>
                        {UserStore.user.website.cloudflare_domain_status?
                            <p className="bg-success text-white rounded-pill d-inline-block py-2 px-2 small">Active</p>
                            :
                            <p className="bg-danger text-white rounded-pill d-inline-block py-2 px-2 small">Inactive</p>
                        }
                    </div>
                </div>
                <div className="row pb-3 mb-md-0">
                    <h5 className="text-secondary">Plan</h5>
                    {
                        UserStore.user.website.isTrial ?
                            <>
                                <div className="col-6 col-md-3 ">
                                    <h6 className="text-muted">Trial</h6>
                                    <p className="text-break text-black">Yes</p>
                                </div>
                                <div className="col-6 col-md-3 ">
                                    <h6 className="text-muted">Trial End Date</h6>

                                    <p className="text-break text-black">
                                        <Moment date={UserStore.user.website.trial_end_date} format={"MM/DD/YYYY"}/>
                                    </p>
                                </div>
                            </>
                            : <>
                                <div className="col-6 col-md-3 ">
                                    <h6 className="text-muted">Plan Status</h6>

                                    <p className="text-break text-black">{UserStore.user.website.planStop ?
                                        <span className="text-danger">Inactive</span> : <span className="text-success ">Active</span>}</p>
                                </div>

                                <div className="col-6 col-md-3 ">
                                    <h6 className="text-muted">Pricing</h6>
                                    <p className="text-break text-black">CAD {UserStore.user.website.plan_price || '-'}/mo</p>
                                </div>
                                <div className="col-6 col-md-3 ">
                                    <h6 className="text-muted">Plan Start Date</h6>
                                    <p className="text-break text-black">{UserStore.user.website.created?.split('T')[0]}</p>
                                </div>
                                <div className="col-6 col-md-3 ">
                                    <h6 className="text-muted">Plan End Date</h6>
                                    <p className={"text-break mb-0 pb-0 " + isPlanEnded() ? "text-danger" : "text-black"}>{UserStore.user.website.plan_end_date?.split('T')[0]}
                                        {
                                            isPlanEnded() &&
                                            <>
                                                {" "}
                                                <NavLink to="/dashboard/transactions"><small>(Renew)</small></NavLink>
                                            </>

                                        }
                                    </p>

                                </div>
                            </>
                    }
                </div>
                {/* {
                    !UserStore.user.website.planStop && <div className="small">
                        <h5 className="text-muted">Actions</h5>
                        <a href="javascript:void(0)"
                           className="nav-link text-danger" type="button" data-bs-toggle="modal"
                           data-bs-target="#deactivateModal">Deactivate</a>
                    </div>
                }
                <DeactivateConfirmationModal deactivating={deactivating} onDeactivating={onDeactivate}/>*/}
            </div>
        </div>
    </>
});
