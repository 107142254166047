import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Facebook, Twitter, Linkedin } from 'react-bootstrap-icons';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import './BlogDetails.scss';
const BlogDetails = ({ title, description, publishedDate, image, recentBlogs }) => {
  return (
    <Container>
      <Row>
        <Col md={8}>
          <h3>{title}</h3>

          <img src={image} alt={title} fluid height={"400"} className="fluid img mx-auto"/>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
          <div className="d-flex align-items-center mb-3">
            <p className="me-3 mb-0">Published on <Moment format='MMM DD YYYY'>{publishedDate}</Moment></p>
            {/* <div>
              <Button variant="light" className="me-2">
                <Facebook />
              </Button>
              <Button variant="light" className="me-2">
                <Twitter />
              </Button>
              <Button variant="light">
                <Linkedin />
              </Button>
            </div> */}
          </div>
        </Col>
        <Col md={4} className="recent-post">
          <h4>Recent Blogs</h4>
          <ul className='text-decoration-none'>
            {recentBlogs && recentBlogs.map((blog) => (
              <li key={blog.post_id} >
                <NavLink to={"/blog/" + blog.post_id}>{blog.post_title}</NavLink>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default BlogDetails;