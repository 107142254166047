import React, {useState} from 'react';

export const AccountCreationContext = React.createContext({});

export const AccountCreationProvider = ({children}) => {
    const [user, setUser] = useState({});
    return <AccountCreationContext.Provider value={{user, setUser}}>
        {children}
    </AccountCreationContext.Provider>
};
