class StorageServiceData {
    constructor() {

    }

    setItem(key, data) {
        if (typeof data !== 'string') {
            data = JSON.stringify(data);
        }
        localStorage.setItem(key, data);
    }

    remove(key) {
        localStorage.removeItem(key);
    }

    getItem(key, json = false) {
        if (!json) {
            return localStorage.getItem(key);
        }
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            return undefined
        }
    }
}
export const StorageService = new StorageServiceData();
