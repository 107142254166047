import {NotFoundIcon} from "../../utils/images-export";
import Button from "../../common/components/button";

export const NotFound = () => {
    return (
        <div className="">
            <div className="container-fluid  my-5">
                <div className="container">
                    <div className="col-12 col-lg-4 col-md-6 mx-auto text-center">
                        <img src={NotFoundIcon} alt="" className="img img-fluid"/>
                        <h5 className="text-muted">Page you are searching is either removed or not valid.</h5>
                        <div className="d-flex gap-3 justify-content-center py-3">
                            <Button title={"Home"} href="/"/>
                            <Button title={"Contact"} href="/contact" type="outline"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
