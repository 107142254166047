import React, {useState} from "react";
import {UploadImage} from "../../common/components/UploadImage";
import {Formik} from "formik";
import * as Yup from "yup";
import {checkDomainExists} from "../Authentication/auth.api";
import {observer} from "mobx-react";

export const WebsiteDetails = React.forwardRef(({store, nextStep}, ref) => {
    const [domainsAdded, setDomainsAdded] = useState([]);
    Yup.addMethod(Yup.string, "domainExists", function (errorMessage) {
        return this.test(`domain-exists`, errorMessage, function (value) {
            const {path, createError} = this;
            return (
                !domainsAdded.includes(value) ||
                createError({path, message: errorMessage})
            );
        });
    });
    const websiteDetailsSchema = Yup.object().shape({
        website_name: Yup.string().min(3, 'At least 3 characters').required('Website name is required'),
        base_url: Yup.string().url('Please enter a valid domain name').required('Website url is required').domainExists('Domain already exists'),
    });

    function updateWebsiteDetails(values, {setSubmitting}) {
        store.setAccountDetails({...store.accountDetails, ...values});
        setSubmitting(false);
    }


    async function checkDomain(domain) {
        if (domainsAdded.includes(domain)) {
            return;
        }
        const response = await checkDomainExists(domain);
        if (!response.api_status) {
            setDomainsAdded([...domainsAdded, domain]);
            ref.current.validateForm();
        }
    }

    return (
        <Formik
            initialValues={{website_name: store.accountDetails.website_name, base_url: store.accountDetails.base_url}}
            validationSchema={websiteDetailsSchema}
            onSubmit={updateWebsiteDetails}
            innerRef={ref}
            validateOnBlur={true}
            validateOnMount={true}
            validate={values => {
                let errors = {};
                if (domainsAdded.includes(values.base_url)) {
                    errors.base_url = 'Domain already exists';
                }
                return errors;
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label "> Website
                            Name </label>
                        <input className="form-control "
                               onChange={handleChange}
                               value={values.website_name}
                               onBlur={handleBlur}
                               placeholder="John Doe Realty" name="website_name" required=""/>
                        <p className="small text-danger pt-1">{errors.website_name && touched.website_name && errors.website_name}</p>
                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label "> Domain name</label>
                        <input className="form-control"
                               value={values.base_url}
                               onChange={handleChange}
                               onBlur={(e) => {
                                   checkDomain(e.target.value);
                                   handleBlur(e)
                               }}
                               placeholder="https://example.com" name="base_url" required=""/>
                        <p className="small text-danger pt-1">{errors.base_url && touched.base_url && errors.base_url}</p>
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="" className="form-label "> Logo</label>
                        <UploadImage width={200} onUpload={(imagePath) => store.setAccountDetails({
                            ...store.accountDetails,
                            logo: imagePath
                        })}
                                     image={store.accountDetails.logo}
                        />
                    </div>

                </form>
            )}
        </Formik>

    )
});
