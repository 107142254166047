import Button from "../../common/components/button";
import React, {useEffect} from "react";
import {observer} from "mobx-react";

export const TemplateDetails = observer(({store}) => {
    function setTemplate(website) {
        store.setAccountDetails({...store.accountDetails, template_id: website.website_id})
    }

    useEffect(function () {
        if (store.templates.length && !store.accountDetails.template_id) {
            store.setAccountDetails({template_id: store.templates[0].website_id});
        }
    }, []);
    return (
        <div className="row template-section">
            <div className="row mt-5">
                {
                    store.templates.map((website, index) => (
                        <div
                            className={"col-12 col-md-4 feature  mb-3 " + (website.website_id === store.accountDetails.template_id ? 'active' : '')}
                            onClick={() => setTemplate(website)}
                            key={index}>
                            <div className="text-center p-3 mb-3 card">
                                <img src={website.screenshot} alt="" className="mb-3 img img-fluid"/>
                                <p className="text-primary card-title">{website.website_name}</p>
                                <div className="d-flex flex-column flex-md-row gap-3">
                                    <Button href={website.base_url} target="_blank" className="btn-sm w-100"
                                            type="outline" title="View"/>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
});
