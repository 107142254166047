import {makeObservable, observable, action} from 'mobx';

class ToasterDataStoreData {
    errorMessage = [];
    warningMessage = [];
    successMessage = [];

    constructor() {
        makeObservable(this, {
            errorMessage: observable,
            warningMessage: observable,
            successMessage: observable,
            reset: observable,
            setErrorMessage: action,
            setWarningMessage: action,
            setSuccessMessage: action,
            clearWarningMessage: action,
            clearErrorMessage: action,
            clearSuccessMessage: action,
        })
    }

    reset() {
        this.errorMessage = [];
        this.warningMessage = [];
        this.successMessage = [];
    }
    setErrorMessage(message) {
        this.errorMessage.push(message);
        setTimeout(() => {
            this.clearErrorMessage();
        }, 5000)
    }

    clearErrorMessage() {
        this.errorMessage = this.errorMessage.filter((d, i) => i !== 0);
    }

    setWarningMessage(message) {
        this.warningMessage.push(message);
        setTimeout(() => {
            this.clearWarningMessage();
        }, 5000)
    }

    clearWarningMessage() {
        this.warningMessage = this.warningMessage.filter((d, i) => i !== 0);
    }

    setSuccessMessage(message) {
        this.successMessage.push(message);
        setTimeout(() => {
            this.clearSuccessMessage();
        }, 5000)
    }

    clearSuccessMessage() {
        this.successMessage = this.successMessage.filter((d, i) => i !== 0);
    }

}

export const ToasterStore = new ToasterDataStoreData();
