import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Header from "./common/components/Header/header";
import routes from "./utils/routes";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Footer from "./common/components/Footer/footer";
import ErrorBoundary from "./common/ErrorBoundary";
import "./utils/variables.scss";
// import 'bootstrap/scss/bootstrap.scss';
import "bootstrap/dist/js/bootstrap.bundle.min";
import ScrollToTop from "./common/components/ScrollTop";
import Helmet from "react-helmet";

import {PrivateRoutesContainer} from './common/components/RouteContainer/index';
import {NavStore} from "./stores/navigation.store";
import {UserStore} from "./stores/user.store";
import {CookiePopup} from "./common/components/CookiePopup";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.Fragment>
    <ErrorBoundary>
      <BrowserRouter>
        <ScrollToTop>
          <Helmet
            titleTemplate="%s | AgentEdge"
            defaultTitle="AgentEdge"
            onChangeClientState={(newState, addedTags, removedTags) =>
              console.log(newState, addedTags, removedTags)
            }
            meta={[{ content: "Some", name: "description" }]}
          ></Helmet>
          <Header userStore={UserStore} />
          <Routes>
            {routes.map(({ Component, path, subRoutes }) => {
              if (subRoutes?.length) {
                return (
                  <Route element={<Component />} path={path}>
                    {subRoutes.map(
                      ({
                        path,
                        Component,
                        isPrivate,
                        isAuthenticationRoute,
                        isAuthenticationTrueRedirect,
                        loggedInDisallowed,
                      }) => {
                        return (
                          <Route
                            element={
                              <PrivateRoutesContainer
                                path={path}
                                isPrivate={isPrivate}
                                userStore={UserStore}
                                isAuthenticationRoute={isAuthenticationRoute}
                                loggedInDisallowed={loggedInDisallowed}
                                isAuthenticationTrueRedirect={
                                  isAuthenticationTrueRedirect
                                }
                                navStore={NavStore}
                              >
                                <Component />
                              </PrivateRoutesContainer>
                            }
                            path={path}
                          />
                        );
                      }
                    )}
                  </Route>
                );
              }
              return <Route element={<Component />} path={path} />;
            })}
          </Routes>
          <CookiePopup />
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </ErrorBoundary>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
