import React from "react";
import NavLink from "../../../common/components/NavLink";
import './index.scss';
export const DashboardSidebar = () => {
    return <div className="dashboard-sidebar">
        <div className="list-group">
            <NavLink to="/dashboard/home" className="list-group-item"> Home</NavLink>
            <NavLink to="/dashboard/transactions" className="list-group-item">Billing</NavLink>
            <NavLink className="list-group-item " to="/dashboard/payment-methods">Payment Methods</NavLink>
            <NavLink className="list-group-item " to="/dashboard/reset-password">Reset Password</NavLink>
            <NavLink className="list-group-item " to="/dashboard/how-to-setup">How To Setup</NavLink>
            {/* <NavLink className="list-group-item " to="/dashboard/refer-earn">Refer & Earn</NavLink> */}

        </div>
    </div>
};
