import Button from "../../common/components/button";
import React, {forwardRef, useEffect, useState} from "react";
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import './index.scss'
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {applyPromoCode, createCharge} from "../Authentication/auth.api";
import {AccountCreationStore} from "../../stores/accountCreation.store";
import {Loading} from "../../common/components/Loading";
import {ToasterStore} from "../../stores/toaster.store";
import {UserStore} from "../../stores/user.store";
import * as moment from 'moment';

const billingDetailsSchema = Yup.object().shape({
    billing_name: Yup.string().min(3, 'At least 3 characters').required('Billing name is required'),
    billing_contact: Yup.number(),
    billing_email: Yup.string().email('Please enter a valid email address.').required('Billing email is required'),
    billing_address: Yup.string().min(10, 'At least 10 characters.').required('Billing address is required'),
    billing_address_2: Yup.string(),
    billing_city: Yup.string().min(2, 'Invalid City').required('City is required'),
    billing_state: Yup.string().min(2, 'Invalid Province').required('Province is required'),
    billing_zip: Yup.string().min(6, 'At least 6 characters.').matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'Please enter a valid zip code').required('Zip code is required'),
    plan: Yup.string(),
    plan_duration: Yup.number(),
    coupon_code: Yup.string()
});
export const PaymentDetails = forwardRef((({store, setSuccess}, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    let defaultPlan = {};
    const [plan, setPlan] = useState(defaultPlan);
    const [taxes, setTaxes] = useState(0);
    const [promoDiscount, setPromoDiscount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [setupFee, setSetupFee] = useState(0);
    const [isTrial, setIsTrial] = useState(false);
    useEffect(function () {
        setLoading(false);
        if (store.accountDetails.coupon_applied) {
            setPromoDiscount(store.accountDetails.coupon_discount);
        }
        if (store.accountDetails.billing_state) {
            setTaxes(AccountCreationStore.provinces.find(province => province.province_name === store.accountDetails.billing_state).total_tax);
        }
        if (store.selectedPlan && Object.keys(store.selectedPlan).length) {
            setPlan({
                duration: store.selectedPlan.duration,
                plan_id: store.selectedPlan.plan_id,
                plan_price: store.selectedPlan.discounted_price
            });
            setSetupFee(store.selectedPlan.setup_fee)
        } else if(store.accountDetails.plan_id){
            if (store.plans.length) {
                setPlan({
                    duration: store.accountDetails.duration,
                    plan_id: store.accountDetails.plan_id,
                    plan_price: store.accountDetails.discounted_price
                });
                setSetupFee(store.accountDetails.setup_fee)
            }
        } else {
            if (store.plans.length) {
                setPlan({
                    duration: 12,
                    plan_id: store.plans[0].plan_id,
                    plan_price: store.plans[0].discounted_price[12]
                });
                setSetupFee(store.plans[0].setup_fee)
            }
        }
    }, []);

    function updatePrice({plan_id, duration}) {
        if (plan_id) {
            setPlan({
                ...plan,
                plan_id,
                plan_price: store.plans.find(_p => +_p.plan_id === +plan_id).discounted_price[plan.duration]
            })
        } else if (duration) {
            setPlan({
                ...plan,
                duration,
                plan_price: store.plans.find(_p => +_p.plan_id === +plan.plan_id).discounted_price[duration]
            })
        }
    }

    const updateWebsiteDetails = async (values, {setSubmitting}) => {
        try {

            if (!stripe || !elements) {
                return;
            }
            if (store.domainExists.includes(values.base_url)) {
                return ToasterStore.setErrorMessage('Domain name already exists.');
            }

            store.setAccountDetails({...store.accountDetails, ...values});
            const cardNumberElement = elements.getElement(CardElement);
            const result = await stripe.createToken(cardNumberElement, {
                currency: process.env.REACT_APP_STRIPE_CURRENCY,
                address_country: 'Canada',
                address_line1: values.billing_address,
                address_line2: values.billing_address_2,
                address_city: values.billing_city,
                address_zip: values.billing_zip,
                address_state: values.billing_state,
                name: values.billing_name
            });
            if (result && result.error && result.error.message) {
                return ToasterStore.setErrorMessage(result.error.message);
            }
            setLoading(true);
            const response = await createCharge({
                ...values,
                website_details: store.accountDetails,
                ...store.accountDetails,
                price: plan.plan_price,
                isTrial,
                token: result.token.id,
                plan_id: plan.plan_id,
                plan_duration: plan.duration,

                plan_name: store.plans.find(p => +p.plan_id === +plan.plan_id).plan_name
            });
            if (!response.api_status) {
                UserStore.clearOtherData();
                setSuccess(true);
            } else {
                ToasterStore.setErrorMessage('Something went wrong.');
            }
            setSubmitting(false);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setSubmitting(false);
        }
    };

    async function setPromoCode() {
        setPromoDiscount(0);
        await applyPromoCode({
            coupon_code: ref.current.values.coupon_code,
            plan_id: plan.plan_id,
            duration: plan.duration
        })
            .then((response) => {
                if (!response.api_status) {
                    store.setAccountDetails({
                        ...store.accountDetails,
                        coupon_code: ref.current.values.coupon_code,
                        coupon_applied: true,
                        coupon_discount: response.coupon_discount_amount
                    });
                    ToasterStore.setSuccessMessage('Promo code applied successfully.');
                    setPromoDiscount(response.coupon_discount_amount);
                } else {
                    store.setAccountDetails({
                        ...store.accountDetails,
                        coupon_code: null,
                        coupon_applied: false,
                        coupon_discount: 0
                    });
                    ToasterStore.setErrorMessage(response.message || 'This promo code is not valid.');
                }
            }).catch((error) => {
                ToasterStore.setErrorMessage(error.message);
            })
    }


    function getTotalPrice() {
        let price = ((plan.plan_price - promoDiscount)) * plan.duration;
        price = price + setupFee;
        return (price * (1 + (taxes / 100))).toFixed(2);
    }

    function updateTax(e) {
        setTaxes(AccountCreationStore.provinces.find(province => province.province_name === e.target.value).total_tax);
    }

    return (
        !loading ? <Formik
                initialValues={{
                    billing_name: store.accountDetails.billing_name,
                    billing_contact: store.accountDetails.billing_contact,
                    billing_email: store.accountDetails.billing_email,
                    billing_address: store.accountDetails.billing_address,
                    billing_address_2: store.accountDetails.billing_address_2,
                    billing_city: store.accountDetails.billing_city,
                    billing_state: store.accountDetails.billing_state,
                    billing_zip: store.accountDetails.billing_zip,
                    coupon_code: store.accountDetails.coupon_code,
                    plan_id: plan.plan_id,
                    plan_duration: plan.plan_duration
                }}
                innerRef={ref}
                validationSchema={billingDetailsSchema}
                onSubmit={updateWebsiteDetails}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} id="footer-contact-form">
                        <div className="row d-none">
                            <div className="col-12 col-md-6">
                                <div className="form-group mt-2">
                                    <label htmlFor="" className="form-label ">
                                        Plan
                                    </label>
                                    <select
                                        className="form-select "
                                        name="plan"
                                        value={values.plan}
                                        onChange={(event) => {
                                            handleChange(event);
                                            updatePrice({plan_id: event.target.value});
                                        }}
                                        onBlur={handleBlur}
                                    >
                                        <option value=""/>
                                        {
                                            AccountCreationStore.plans.map((plan, i) => (
                                                <option value={plan.plan_id} key={i}>{plan.plan_name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="small text-danger pt-1">{errors.plan && touched.plan && errors.plan}</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mt-2">
                                    <label htmlFor="" className="form-label ">
                                        Plan Duration
                                    </label>
                                    <Field name="plan_duration" as="select" value={values.plan_duration}
                                           className="form-select "
                                           onChange={(event) => {
                                               handleChange(event);
                                               updatePrice({duration: event.target.value});
                                           }}
                                           onBlur={handleBlur}>
                                        <option value=""/>
                                        <option value="12">Annual</option>
                                        <option value="6">Semi-Annual</option>
                                        <option value="1">Monthly</option>
                                    </Field>
                                    <p className="small text-danger pt-1">{errors.plan_duration && touched.plan_duration && errors.plan_duration}</p>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="" className="form-label ">
                                Billing Name
                            </label>
                            <input
                                className="form-control "
                                name="billing_name"
                                value={values.billing_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <p className="small text-danger pt-1">{errors.billing_name && touched.billing_name && errors.billing_name}</p>
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="" className="form-label ">
                                Billing Email
                            </label>
                            <input
                                className="form-control "
                                value={values.billing_email}

                                name="billing_email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <p className="small text-danger pt-1">{errors.billing_email && touched.billing_email && errors.billing_name}</p>
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="" className="form-label ">
                                Billing Phone No.
                            </label>
                            <input
                                className="form-control "
                                value={values.billing_contact}

                                type="number"
                                name="billing_contact"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <p className="small text-danger pt-1">{errors.billing_contact && touched.billing_contact && errors.billing_contact}</p>
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="" className="form-label ">
                                Billing Address Line 1
                            </label>
                            <input
                                className="form-control "
                                type={"text"}
                                value={values.billing_address}
                                name="billing_address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <p className="small text-danger pt-1">{errors.billing_address && touched.billing_address && errors.billing_address}</p>
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="" className="form-label ">
                                Billing Address Line 2
                            </label>
                            <input
                                className="form-control "
                                type={"text"}
                                value={values.billing_address_2}
                                name="billing_address_2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <p className="small text-danger pt-1">{errors.billing_address_2 && touched.billing_address_2 && errors.billing_address_2}</p>
                        </div>
                        <div className="d-flex flex-column flex-md-row gap-0 gap-md-3">
                            <div className="form-group flex-grow-1 ">
                                <label htmlFor="" className="form-label ">
                                    City
                                </label>
                                <input
                                    className="form-control "
                                    name="billing_city"
                                    value={values.billing_city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <p className="small text-danger pt-1">{errors.billing_city && touched.billing_city && errors.billing_city}</p>
                            </div>
                            <div className="form-group flex-grow-1 ">
                                <label htmlFor="" className="form-label ">
                                    Province
                                </label>
                                <select
                                    className="form-select "
                                    name="billing_state"
                                    value={values.billing_state}
                                    onChange={(e) => {
                                        updateTax(e);
                                        return handleChange(e)
                                    }}
                                    onBlur={handleBlur}
                                >
                                    <option value=""/>
                                    {
                                        AccountCreationStore.provinces.map((province, i) => <option
                                            key={i}
                                            value={province.name}>{province.province_name}</option>)
                                    }
                                </select>
                                <p className="small text-danger pt-1">{errors.billing_state && touched.billing_state && errors.billing_state}</p>
                            </div>
                            <div className="form-group flex-grow-1">
                                <label htmlFor="" className="form-label ">
                                    Zip Code
                                </label>
                                <input
                                    className="form-control "
                                    name="billing_zip"
                                    value={values.billing_zip}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <p className="small text-danger pt-1">{errors.billing_zip && touched.billing_zip && errors.billing_zip}</p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-end gap-3">
                            <div className="form-group  flex-grow-1">
                                <label htmlFor="" className="form-label ">Have Coupon?</label>
                                <input
                                    className="form-control "
                                    name="coupon_code"
                                    disabled={promoDiscount}
                                    value={values.coupon_code}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <p className="small text-danger pt-1"
                                >{errors.coupon_code && touched.coupon_code && errors.coupon_code}</p>
                            </div>
                            {
                                promoDiscount === 0 ?
                                    <p className="nav-link" onClick={setPromoCode} style={{cursor: 'pointer'}}>Apply</p>
                                    : <p className="nav-link text-danger" onClick={() => setPromoDiscount(0)}
                                         style={{cursor: 'pointer'}}>Remove</p>
                            }
                        </div>
                        {promoDiscount > 0 &&
                        <small className="mb-3 mt-n1 d-block text-success " style={{marginTop: '-1rem'}}><b>You get
                            CAD {promoDiscount} discount per month.</b></small>}
                        <div className="mt-2 table-responsive">
                            <h5>Order Details</h5>
                            <table className="table small table-borderless">
                                <tbody>
                                <tr>
                                    <td>Plan Price</td>
                                    <td className="text-end">CAD {(plan.plan_price - promoDiscount).toFixed(2)} </td>
                                </tr>
                                <tr>
                                    <td>Duration</td>
                                    <td className="text-end">{plan.duration} months</td>
                                </tr>
                                {
                                    promoDiscount > 0 &&

                                    <tr className="text-success">
                                        <td>Coupon Savings({values.coupon_code})</td>
                                        <td className="text-end"><b> - CAD {(promoDiscount * plan.duration).toFixed(2)}</b></td>
                                    </tr>
                                }

                                <tr>
                                    <td>
                                    </td>
                                    <td className={"text-end"}>CAD {(plan.plan_price - promoDiscount)?.toFixed(2)} x {plan.duration} mo</td>
                                </tr>
                                <tr>
                                    <td>
                                        Setup Fee
                                    </td>
                                    <td className={"text-end"}>CAD {setupFee?.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Taxes
                                    </td>
                                    <td className={"text-end"}>{taxes > 0 ? taxes + '%' : 'To be calculated...'}</td>
                                </tr>
                                <tr className="border-top " style={{fontSize: '1rem'}}>
                                    <td>
                                        Total
                                    </td>
                                    <td className={"text-end"}>CAD {getTotalPrice()}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="" className="form-label mb-3 ">
                                Card Details
                            </label>
                            {/*<PaymentElement className="strong"/>*/}
                            <CardElement options={{hidePostalCode: true}}/>
                        </div>
                        <Button
                            title={"Pay CAD " + (getTotalPrice()) + (+plan.duration !== 1 ? " (" + ((plan.plan_price - promoDiscount)?.toFixed(2)) + "/month)" : "/month")}
                            type="solid"
                            className="mt-3 btn-block w-100"
                            onClick={() => {
                                setIsTrial(false);
                                ref.current.submitForm()
                            }}
                            isSubmit={false}
                        />
                        {/* <small className="text-muted"><i>You will charged CAD {getTotalPrice()} now.
                            Your plan will expire
                            on {moment().add(plan?.duration || 1, 'month').format('MM-DD-YYYY')}.</i></small> */}
                        <p className="text-center py-2 my-2">-OR-</p>
                        <Button
                            title={"Start Free Trial"}
                            type="outline"
                            className="btn-block w-100"
                            onClick={() => {
                                setIsTrial(true);
                                ref.current.submitForm()
                            }}
                            isSubmit={false}
                        />
                        <small className="text-muted">
                            <i>Your plan will start
                                on {moment().add(1, 'month').format('MM-DD-YYYY')} and
                                you will charged CAD {getTotalPrice()}.
                            </i>
                        </small>
                        <br/>
                        <br/>
                        <small className="text-muted"><i>*Your plan will be auto renewed and you can pause it any
                            time.</i></small>
                    </form>
                )}
            </Formik>
            :
            <>
                <Loading/>
                <p className="text-center">Your account creation is in process.</p>
            </>

    )

}));
