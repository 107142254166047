import React, {useState} from 'react';
import {AccountCreationStore} from "../../stores/accountCreation.store";
import {DoneIcon, Plan1, Plan2} from "../../utils/images-export";
import Button from "../../common/components/button";

export const SelectPlan = ({nextStep}) => {
    const [loading, setLoading] = useState(false);
    const [pricing, setPricing] = useState(AccountCreationStore.accountDetails.duration || 12);
    const getDiscountedValue = ({plan, discount}) => {
        return (plan * ((1 - discount / 100))).toFixed(0);
    };
    const register = (plan) => {
        AccountCreationStore.setSelectedPlan(plan);
        nextStep();
    };
    return <>
        <div className="row">
            <div className="col-12">
                <ul className="nav nav-pills nav-fill mb-3 ">
                    <li className="nav-item" style={{cursor: 'pointer'}}>
                        <span className={"nav-link " + (pricing === 12 && 'active')}
                              aria-current="page"
                              onClick={() => setPricing(12)}>Yearly</span>
                    </li>
                    <li className="nav-item" style={{cursor: 'pointer'}}>
                        <span className={"nav-link " + (pricing === 1 && 'active')}
                              onClick={() => setPricing(1)}
                        >Monthly</span>
                    </li>
                </ul>
            </div>
        </div>
        <div className="row">
            {
                AccountCreationStore.plans && AccountCreationStore.plans.length > 0 &&
                AccountCreationStore.plans.map((plan, key) => <>
                    <div className="col-12 col-lg-6  mb-3" key={'plan-' + key}>
                        <div className="card">
                            <div className="card-body">
                                <p className="small mb-0">{plan.plan_name}</p>
                                <h2 className="text-primary card-title d-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-column">
                                        {
                                            plan.discount[pricing] > 0 &&
                                            <span style={{fontSize: '1rem'}}
                                                  className="text-success">{plan.discount[pricing]}% Discount</span>
                                        }
                                        {
                                            plan.discount[pricing] > 0 &&
                                            <small
                                                style={{fontSize: '1rem',}}><strike>CAD {plan.plan_price}/Month</strike></small>
                                        }
                                        <span>CAD {plan.discounted_price[pricing]}/Month</span>

                                    </div>
                                    <img src={key === 0 ? Plan2 : Plan1} alt="" width={100}/>
                                </h2>
                                <div className="d-flex flex-column  mt-3">
                                    {
                                        plan.plan_features.map((featureHead, head) => (
                                            <div key={'feature-' + featureHead.title}>
                                                <h6 className="text-muted">{featureHead.title}</h6>
                                                {
                                                    featureHead.features.map((feature, i) => (
                                                        <p className="d-flex gap-2"
                                                           key={'feature-' + featureHead.title + '-' + i}>
                                                            <img
                                                                src={DoneIcon} alt=""/>{feature}
                                                        </p>
                                                    ))
                                                }
                                            </div>

                                        ))
                                    }

                                </div>
                                <Button onClick={() => register({
                                    plan_id: plan.plan_id,
                                    price: plan.plan_price,
                                    discounted_price: plan.discounted_price[pricing],
                                    duration: pricing,
                                    setup_fee: plan.setup_fee
                                })}
                                        className="w-100"
                                        type={AccountCreationStore.accountDetails.plan_id === plan.plan_id? 'solid': 'outline'}
                                        title="Select"/>
                            </div>
                        </div>
                    </div>
                </>)

            }
        </div>
    </>
};
