import Button from "../../../common/components/button";
import React from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import {useNavigate} from 'react-router-dom';
import {ToasterStore} from "../../../stores/toaster.store";
import {resetPassword} from "../../Authentication/auth.api";

const loginSchema = Yup.object().shape({
    password: Yup.string().min(6, 'Password should be at least 6 chars').required('Password is required'),
    confirmPassword: Yup.string().test('passwords-match', 'Passwords must match', function(value){
        return this.parent.password === value
    })
});

export const ResetPassword = () => {
    const history = useNavigate();
    const loginValue = {
        email: '',
        confirmPassword: ''
    };

    async function submitLogin(values, {setSubmitting, resetForm}) {
        const response = await resetPassword(values);
        if (!response.api_status) {
            ToasterStore.setSuccessMessage('Password reset successfully.');
        } else {
            ToasterStore.setErrorMessage(response.message);
        }
        setSubmitting(false);
        resetForm();
    }

    return (
        <div>
            <h4 className="text-center text-primary underline">Reset Password</h4>
            <div className="container-fluid  my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 col-md-8 mx-auto ">
                            <Formik
                                initialValues={loginValue}
                                validationSchema={loginSchema}
                                onSubmit={submitLogin}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                  }) => (
                                    <form onSubmit={handleSubmit}>

                                        <div className="form-group mt-2">
                                            <label htmlFor="" className="form-label "> Password</label>
                                            <input className="form-control " type="password" name="password"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.password}/>
                                            <p className="small text-danger pt-1">{errors.password && touched.password && errors.password}</p>
                                        </div>

                                        <div className="form-group mt-2">
                                            <label htmlFor="" className="form-label "> Confirm Password</label>
                                            <input className="form-control " type="password" name="confirmPassword"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.confirmPassword}/>
                                            <p className="small text-danger pt-1">{errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}</p>
                                        </div>

                                        <Button title={"Update"} type="solid"
                                                isSubmit={true}
                                                className="mt-3 btn-block w-100" disabled={isSubmitting}/>

                                    </form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
