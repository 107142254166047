import React, {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {Loading} from "../../../common/components/Loading";

export const PaymentConfirmationModal = ({amount, paying, onPayNow}) => {
    useEffect(() => () => {
        let el = document.querySelector('.modal-backdrop');
        if (el) {
            el.remove();
        }
    }, []);
    return <div className="modal fade " id="paymentModal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Confirm</h5>
                    <button type="button" className="paymentModalClose btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                </div>
                <div className="modal-body">
                    <p className="text-muted">
                        We will try to charge <strong>{amount}</strong> from your primary payment method. If payment
                        method is not added
                        or
                        invalid, please update that
                        in <NavLink to="/dashboard/payment-methods">payment methods</NavLink> settings.</p>
                </div>
                <div className="modal-footer">
                    {
                        paying ?
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <Loading classes="mt-0"/>
                            </div>
                            :
                            <button type="button" className="btn btn-primary" onClick={onPayNow}>Pay Now</button>
                    }
                </div>
            </div>
        </div>
    </div>
};
