import {FAQ} from "../../common/components/Faq";
import {observer} from "mobx-react";
import {AccountCreationStore} from "../../stores/accountCreation.store";
import {ContactQuery} from "../../common/components/ContactQuery";

export const FAQPage = observer(() => {
    return <>
        <FAQ list={AccountCreationStore.faq}/>
        <ContactQuery/>
    </>
});
