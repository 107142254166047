import {observer} from "mobx-react";
import {ToasterStore} from "../../../stores/toaster.store";

export const ToasterMessage = observer(() => {

    return <div className="position-fixed  d-flex flex-column gap-2 " style={{zIndex: 1100000,top: '1rem', right: '1rem', maxWidth: '300px'}}>
        {
            ToasterStore.errorMessage.map((er, i) =>
                    <div
                        key={i}
                        className="p-3 bg-danger text-light rounded" >
                        <strong className="me-auto">{er}</strong>
                    </div>
                )
        }        {
            ToasterStore.successMessage.map((er, i) =>
                    <div
                        key={i}

                        className="p-3 bg-success text-light rounded" >
                        <strong className="me-auto">{er}</strong>
                    </div>
                )
        }        {
            ToasterStore.warningMessage.map((er, i) =>
                    <div
                        key={i}

                        className="p-3 bg-warning text-light rounded" >
                        <strong className="me-auto">{er}</strong>
                    </div>
                )
        }
    </div>
});
