import {action, makeObservable, observable} from "mobx";
import {StorageService} from "./storage.service";
import {setData} from "../pages/Authentication/auth.api";
import {
  AgentAgreementStep1,
  GoogleKeysImage,
  Homes4You,
  JatinderChahalWebsite,
  JoraKahlonWebsite,
  PVMinhasWebsitefrom,
  ServerSetupImage,
  TajinderWebsite
} from "../utils/images-export";

class AccountCreationStoreData {
  accountDetails = {};
  plans = [];
  plansLoading = false;
  selectedPlan = {};
  faq = [];
  headerOffer = [];
  templates = [];
  testimonials = [];
  domainExists = [];
  websitesList = [];
  provinces = [];
  websiteConfig={};
  howToGuide = [
    {
      img: GoogleKeysImage,
      title: 'Setup Google Keys',
      path: '/how-to-setup/google-keys',
      description: 'We are using google keys for maps, recaptcha, analytics and site verification. You can setup google\n' +
          '                                keys by following this post. are using google keys for maps, recaptcha, analytics and site verification. You can setup google\n' +
          '                                keys by following this post.',
      modified: '02/02/2022'
    }, {
      img: AgentAgreementStep1,
      title: 'Agent Data Agreement',
      path: '/how-to-setup/agent-agreement',
      description: `Step by step guide to TRREBB agent data agreement`,
      modified: '02/02/2022'
    }, {
      img: ServerSetupImage,
      path: '/how-to-setup/name-server',
      title: 'Complete Your nameserver setup',
      description: 'Step by step guide to update your nameserver.',
      modified: '02/02/2022'

    }
  ];

  constructor() {
    makeObservable(this, {
      accountDetails: observable,
      plansLoading: observable,
      testimonials: observable,
      headerOffer: observable,
      websitesList: observable,
      faq: observable,
      reset: observable,
      selectedPlan: observable,
      domainExists: observable,
      plans: observable,
      setAccountDetails: action,
      setSelectedPlan: action,
    });
    let accountDetails = StorageService.getItem('accountDetails', true);
    if (accountDetails) {
      this.accountDetails = accountDetails;
    }
    if (!this.plans.length) {
      this.plansLoading = true;
      setData().then(({plans, faq, websites, provinces, testimonials, templates, websiteConfig}) => {
        this.plansLoading = false;
        this.plans = plans;
        this.provinces = provinces;
        this.websitesList = websites;
        this.faq = faq;
        this.testimonials = testimonials;
        this.headerOffer = testimonials;
        this.templates = templates;
        this.websiteConfig = websiteConfig;
      }).catch(_ => {
        this.plansLoading = false;
        this.plans = [];
      })
    }

  }

  reset() {
    this.accountDetails = {};
  }

  setSelectedPlan(plan) {
    this.selectedPlan = plan;
    this.setAccountDetails({...plan})
  }
  setDomainExists(domain) {
    this.domainExists.push(domain);
  }
  setAccountDetails(details) {
    this.accountDetails = {...this.accountDetails, ...details};
    //update billing info as agent info if no billing info provided
    if (!this.accountDetails.billing_name) {
      this.accountDetails.billing_name = this.accountDetails.salesman_name;
      this.accountDetails.billing_email = this.accountDetails.salesman_email;
      this.accountDetails.billing_contact = this.accountDetails.salesman_contact;
      this.accountDetails.billing_address = this.accountDetails.company_address;
    }
    StorageService.setItem('accountDetails', JSON.stringify(this.accountDetails));
  }
}
export const AccountCreationStore = new AccountCreationStoreData();
