import React, {useEffect, useState} from 'react';
import NavLink from "../NavLink";
import './cookie-popup.scss';
export const CookiePopup = () => {
    const [showCookie, setShowCookie] = useState(false);
    useEffect(function () {
        if (!localStorage.getItem('cookieAccepted')) {
            setShowCookie(true);
        }
    }, []);

    function acceptCookie() {
        localStorage.setItem('cookieAccepted', '1');
        setShowCookie(false);
    }

    if (!showCookie) {
        return <></>;
    }
    return <div id="cookie-popup">
        <div className="title-wrap">
            <h4>Cookie Consent</h4>
        </div>
        <div className="content-wrap">
            <div className="msg-wrap">
                <p>This website uses cookies or similar technologies, to enhance your browsing experience and provide
                    personalized recommendations. By continuing to use our website, you agree to our {" "}
                    <NavLink to="/policies/privacy-policy">Privacy Policy</NavLink></p>
                <div className="btn-wrap">
                    <button className="btn btn-primary" onClick={acceptCookie}>Accept</button>
                </div>
            </div>
        </div>
    </div>
};
